import React from 'react';
import { AsyncPaginate } from 'react-select-async-paginate';
import axios from 'axios';
import { useRecyclers } from '../../../hooks/hooks';

const AsyncRecyclerDropDown = (props) =>
  // { column: { filterValue = '', setFilter = () => {}, preFilteredRows = [] } = {} },
  // props
  {
    const { page, size, value, setValue = () => {}, onInputChange, inputValue, isDisabled } = {
      ...props,
    };
    const [
      {
        value: { data: recyclerData = [] },
      },
      { doFetchRecycler },
    ] = useRecyclers();

    async function loadOptions(val, searchQuery, loadedOptions) {
      const params = {
        page: Number(loadedOptions?.page || page),
        size,
        businessName: val,
      };
      const ourRequest = axios.CancelToken.source();
      const response = await doFetchRecycler({ data: params, signal: ourRequest });
      const recyclers = [];
      response?.data?.forEach((element) => {
        if (element?.businessName) {
          recyclers.push({
            label: `${element?.businessName} `,
            value: element?.id,
            recyclerObj: element,
          });
        }
      });
      return {
        options: recyclers,
        hasMore: response?.data?.length === 20,
        additional: {
          page: (loadedOptions?.page || 0) + 1,
          size: 20,
        },
      };
    }

    return (
      <AsyncPaginate
        value={value}
        loadOptions={loadOptions}
        // onChange={(e) => {
        //   setValue(e);
        //   setFilter(e?.value);
        // }}
        onChange={setValue}
        isClearable
        isDisabled={isDisabled}
        onInputChange={onInputChange}
        inputValue={inputValue}
        debounceTimeout={300}
      />
    );
  };

export default AsyncRecyclerDropDown;
