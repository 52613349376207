import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  customColumns: [],
  customData: {},
};

const CustomTableSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setCustomeTable: (state, action) => {
      state.customColumns = action.payload;
    },
    setCustomeTableData: (state, action) => {
      state.customData = action.payload;
    },
  },
});

export const customTableActions = CustomTableSlice.actions;
export default CustomTableSlice.reducer;
