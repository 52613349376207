import React from 'react';
import PropTypes from 'prop-types';
import { Card } from 'react-bootstrap';
import classNames from 'classnames';
import Loader from './Loader/Loader';

const PageContainer = (props) => {
  const { children, className = '', loading = false, style = {} } = props;
  return (
    <Card
      className={classNames(
        'PageContainer',
        {
          'justify-content-center': loading,
        },
        className
      )}
      style={style}>
      {/* <AppBreadcrumb {...props} /> */}
      {loading ? <Loader /> : children}
    </Card>
  );
};

PageContainer.propTypes = {
  children: PropTypes.any,
  className: PropTypes.string,
  style: PropTypes.object,
};

export default PageContainer;
