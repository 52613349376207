import { useSelector } from 'react-redux';
import { filter, flatMap, keyBy } from 'lodash';

export default function useStakeholders() {
  const {
    kamUsers,
    recyclers,
    aggregators,
    currentAccount,
    isCurrentUser,
    accountUsers,
    rsmUsers,
    spocUsers,
    kamList,
  } = useSelector(({ user, customer, kamUser, accountUser }) => {
    const flattedKamUsers = flatMap(kamUser?.kamUsers);
    const activeKamUsers = filter(flattedKamUsers, (option) => option?.activated);

    return {
      currentAccount: user.accountDetails,
      recyclers: keyBy(customer.recyclerListConstant, 'id'),
      aggregators: keyBy(customer.aggregatorList, 'id'),
      kamUsers: keyBy(kamUser.kamUsers, 'userId'),
      kamList: activeKamUsers,
      accountUsers: keyBy(accountUser?.accountUsers, 'userId'),
      isCurrentUser: (id) => id === currentAccount?.id,
      rsmUsers: keyBy(kamUser.rsmUsers, 'id'),
      spocUsers: kamUser.spocUsers,
    };
  });
  const isKamUser = (kamUserId) => !!kamUsers[kamUserId];
  return {
    kamUsers,
    recyclers,
    currentAccount,
    aggregators,
    isCurrentUser,
    isKamUser,
    accountUsers,
    rsmUsers,
    spocUsers,
    kamList,
  };
}
