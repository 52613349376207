import React, { useEffect, useRef, useState } from 'react';
import { isUndefined } from 'lodash';
import PlacesAutocomplete, { geocodeByAddress, getLatLng } from 'react-places-autocomplete';
import './GooglePlaceAutocomplete.scss';
import { FormControl, InputGroup } from 'react-bootstrap';
import { FaMapMarkerAlt } from '@react-icons/all-files/fa/FaMapMarkerAlt';
import { useRegion } from '../../../hooks';
import { SearchIcon } from '../../../assets/img/imageExport/images';

const GooglePlaceAutocomplete = (props) => {
  const { value, disabled = false, showSearchIcon = false } = { ...props };
  const [inputValue, setInputValue] = useState(value);
  const { statesFlat } = useRegion();
  const ref = useRef(null);

  useEffect(() => {
    setInputValue(value);
  }, [value]);

  const addressObj = {
    street: '',
    zipCode: '',
    latLng: { lat: '', lng: '' },
    city: '',
    state: '',
  };
  const parseResult = (result) => {
    function isEqual(a, b) {
      return a?.trim()?.toLocaleString() === b?.trim()?.toLocaleString();
    }

    const addrComponent = result.address_components;
    // const cityName =
    // addrComponent.find((comp) => comp.types.indexOf('locality') >= 0)?.long_name;
    const zipCode = addrComponent.find((comp) => comp.types.indexOf('postal_code') >= 0)?.long_name;
    const stateName = addrComponent?.find((i) => i?.types?.includes('administrative_area_level_1'))
      ?.long_name;

    addressObj.state = statesFlat?.find((s) => isEqual(s?.name, stateName)) || '';
    // addressObj.street = result.formatted_address;
    // if (!addressObj.state) {
    //   addressObj.city = addressObj.state.districts.find((d) => isEqual(d.name, cityName));
    // }
    addressObj.zipCode = zipCode;

    let city = addrComponent?.find((i) => i?.types?.includes('locality'));

    if (isUndefined(city) || city === null) {
      city = addrComponent?.find((i) => i?.types?.includes('administrative_area_level_3'));
      if (isUndefined(city) || city === null) {
        city = addrComponent?.find((i) => i?.types?.includes('establishment'));
      }
      if (isUndefined(city) || city === null) {
        city = addrComponent?.find((i) => i?.types?.includes('administrative_area_level_1'));
      }
    }

    addressObj.city = city;
  };

  const handleSelect = (address, zipCode, city, sourceType, sourceName) => {
    setInputValue(address);

    geocodeByAddress(address)
      .then((results) => {
        addressObj.latLng = {
          lat: results[0].geometry.location.lat(),
          lng: results[0].geometry.location.lng(),
        };
        parseResult(results[0]);
        addressObj.street = address;
        addressObj.sourceType = sourceType;
        // addressObj.city = city ;
        addressObj.sourceName = sourceName;
        if (addressObj.zipCode === undefined) {
          addressObj.zipCode = zipCode;
        }
      })
      .finally((e) => {
        props.handleSelect(addressObj);
      })
      .catch((error) => {
        if (error) {
          // handle error
        }
      });
  };

  const handleChange = (input) => {
    setInputValue(input);
    props.handleChange(input);
  };

  return (
    <PlacesAutocomplete value={inputValue} onChange={handleChange} onSelect={handleSelect}>
      {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
        <InputGroup ref={ref}>
          {showSearchIcon && (
            <InputGroup.Prepend>
              <InputGroup.Text
                style={{
                  backgroundColor: 'white',
                }}>
                <SearchIcon style={{ height: '1rem' }} />
              </InputGroup.Text>
            </InputGroup.Prepend>
          )}
          <FormControl
            {...getInputProps({
              placeholder: props.placeholder || 'Search Places ...',
              className: props.className,
            })}
            disabled={disabled}
            isInvalid={props.isInvalid}
          />
          <InputGroup.Append>
            <InputGroup.Text id="basic-addon1" style={{ backgroundColor: 'white' }}>
              <FaMapMarkerAlt />
            </InputGroup.Text>
          </InputGroup.Append>
          <div className="w-100 GooglePlaceAutocomplete p-0 autocomplete-dropdown-container">
            {suggestions.map((suggestion) => {
              // inline style for demonstration purpose
              const style = suggestion.active
                ? { backgroundColor: '#e3edff', cursor: 'pointer' }
                : { backgroundColor: '#ffffff', cursor: 'pointer' };
              return (
                <div
                  {...getSuggestionItemProps(suggestion, {
                    className: 'result-row border-bottom',
                    style,
                  })}>
                  <span>{suggestion.description}</span>
                </div>
              );
            })}
          </div>
        </InputGroup>
      )}
    </PlacesAutocomplete>
  );
};

export default GooglePlaceAutocomplete;
