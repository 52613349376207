import { combineReducers } from '@reduxjs/toolkit';
import UserReducer from './User/user.reducer';
import AuthReducer from './Auth/auth.reducer';
import CatalogReducer from './Catalog/catalog.reducer';
import RegionReducer from './Region/region.reducer';
import CustomerReducer from './Customer/customer.reducer';
import ConfigReducer from './Config/config.reducer';
import KamUserReducer from './KamUser/kamUser.reducer';
import AccountUserReducer from './AccountsUser/accountUser.reducer';
import DocumentReducer from './Documents/document.reducer';
import customeTableReducer from './CustomTable/customTable.reducer';
import RoleReducer from './Roles/role.reducer';

const appReducer = combineReducers({
  user: UserReducer,
  auth: AuthReducer,
  catalog: CatalogReducer,
  region: RegionReducer,
  customer: CustomerReducer,
  config: ConfigReducer,
  kamUser: KamUserReducer,
  accountUser: AccountUserReducer,
  document: DocumentReducer,
  CustomeTable: customeTableReducer,
  role: RoleReducer,
});

const rootReducer = (state, action) => {
  if (action?.type === 'auth/logout') {
    state = undefined;
  }
  return appReducer(state, action);
};

export default rootReducer;
