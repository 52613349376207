import { createSlice } from '@reduxjs/toolkit';
import { keyBy, filter } from 'lodash';

const initialState = {
  states: null,
  cities: null,
  zones: null,
};

const RegionSlice = createSlice({
  name: 'region',
  initialState,
  reducers: {
    setRegion: (state, action) => {
      state.states = keyBy(filter(action.payload, ['type', 'STATE']), 'id');
      state.cities = keyBy(filter(action.payload, ['type', 'CITY']), 'id');
    },
    setZonesList: (state, action) => {
      state.zones = action.payload;
    },
  },
});
export const regionActions = RegionSlice.actions;
export default RegionSlice.reducer;
