import { createSlice } from '@reduxjs/toolkit';

const initialAuth = {
  businessTypes: null,
};

const ConfigSlice = createSlice({
  name: 'config',
  initialState: initialAuth,
  reducers: {
    setConfig: (state, action) => {
      return { businessTypes: action.payload };
    },
  },
});
export const configActions = ConfigSlice.actions;
export default ConfigSlice.reducer;
