import React from 'react';
import { SALE_ORDER_DOCUMENT_STATUS } from '../../shared/constants/Constants';

export const DocumentStatusLabel = ({ docStatus }) => {
  if (docStatus === undefined) return <div>NA</div>;

  const { textColor, name, statusText, role } = SALE_ORDER_DOCUMENT_STATUS?.[docStatus];
  return (
    <div>
      {statusText && role ? (
        <span style={{ textAlign: 'left' }}>
          <span style={{ color: `${textColor}` }} className="font-bold p-1">
            {statusText}
          </span>
          <span style={{ color: '#A2A2A2', fontSize: '11px' }}>{`- by ${role}`}</span>
        </span>
      ) : (
        <span style={{ color: `${textColor}`, textAlign: 'left' }} className="font-bold p-1">
          {name ?? 'NA'}
        </span>
      )}
    </div>
  );
};
