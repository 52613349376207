import PropTypes from 'prop-types';
import React from 'react';
import { useRegion } from '../../../hooks';
import DropdownSelect from '../DropdownSelect';

const ZonesDropDown = ({ onChange, value, ...props }) => {
  const { zones } = useRegion();

  return (
    <DropdownSelect
      getOptionLabel={(option) => {
        return option?.name;
      }}
      onChange={onChange}
      getOptionValue={(option) => option?.id}
      options={zones}
      value={value}
      {...props}
    />
  );
};

ZonesDropDown.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func,
};

export default ZonesDropDown;
