import { useSelector } from 'react-redux';
import { flatMap, keyBy, groupBy } from 'lodash';

export default function useCatalog() {
  const { items = {}, categories, qualityParams, materialTypes, activeSellerTypes } = useSelector(
    ({ catalog }) => catalog
  );

  const flatMapItems = flatMap(items);

  const materialNameGroupedItems = groupBy(flatMapItems, (option) => {
    return option?.categoryDTO?.materialType?.id;
  });

  return {
    items,
    categories,
    qualityParams,
    itemsFlat: flatMapItems,
    categoriesFlat: flatMap(categories),
    qualityParamsFlat: flatMap(qualityParams),
    materialTypes,
    materialTypesList: keyBy(materialTypes, 'id'),
    materialTypesFlat: flatMap(materialTypes),
    categoryItems: groupBy(items, 'categoryId'),
    materialNameGroupedItems,
    activeSellerTypes,
  };
}
