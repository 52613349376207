import { useSelector } from 'react-redux';
import { flatMap } from 'lodash';

export default function useRole() {
  const { groups, modules, roles } = useSelector(({ role }) => {
    return role;
  });

  const groupsData = groups?.data?.groups;
  const modulesData = modules?.data?.modules;
  const rolesData = roles?.data?.authorities;

  return {
    groupsData,
    groupsDataFlat: flatMap(groupsData),
    modulesData,
    modulesDataFlat: flatMap(modulesData),
    rolesData,
    rolesDataFlat: flatMap(rolesData),
  };
}
