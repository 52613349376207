import React from 'react';
import { Form } from 'react-bootstrap';
import Rating from 'react-rating';
import { FaStar } from '@react-icons/all-files/fa/FaStar';
import { FaRegStar } from '@react-icons/all-files/fa/FaRegStar';

const CustomRatingComponent = ({
  name,
  error,
  touched,
  label = 'Choose rating',
  value = 0,
  ...props
}) => {
  return (
    <>
      <Rating
        stop={5}
        initialRating={value}
        fullSymbol={<FaStar className="icon mr-2" />}
        emptySymbol={<FaRegStar className="icon mr-2" />}
        readonly
        {...props}
      />
      {touched && error ? (
        <Form.Control.Feedback type="invalid">{error}</Form.Control.Feedback>
      ) : null}
    </>
  );
};

export default CustomRatingComponent;
