import { keyBy, isArray, map, isEmpty, sumBy, pick, minBy } from 'lodash';
import { useContext } from 'react';
import * as _ from 'lodash';
import useCatalog from './useCatalog';
import {
  getArialDistance,
  getOrderItems,
  getRecykalMargin,
  getRecykalMarginPercentage,
  percentage,
  toFloat,
  toRupees,
} from '../shared/utils/Helper';
import useStakeholders from './useStakeholders';
import { ASSESSMENT_STATUS, ORDER_STATUS, ROLE_PRIORITY } from '../shared/constants/Constants';
import { getSyatemUser } from '../services/backend-api';

export default function useMapper() {
  const { kamUsers, recyclers } = useStakeholders();
  const { items, qualityParams } = useCatalog();
  const _getRecycler = (id) => recyclers[id];
  const _getKamUser = (id) => kamUsers[id];
  const _getRecyclerAddress = (addressId, recyclerId) => {
    const recycler = _getRecycler(recyclerId) || {};
    const recyclerAddresses = keyBy(recycler.address, 'id');
    return recyclerAddresses[addressId] || {};
  };

  const getCreatedByName = (order) => {
    return order?.createdBy === order?.seller?.userId
      ? order?.seller?.businessName
      : _getKamUser(order?.createdBy)?.firstName;
  };
  function _orderMapper(orderData) {
    if (!orderData) return {};
    const order = { ...orderData };

    const { recyclerId, kamUserId, items: orderItems } = order;

    // map recycler purchaseOrder.recyclerId

    const qualityDeduction = order?.recyclerQcParam ? JSON.parse(order?.recyclerQcParam) : null;
    if (recyclerId) {
      order.recycler = _getRecycler(recyclerId);
    }
    order.shippingAddress = orderData.shippingAddress;

    // map kamUser kamUserId
    if (kamUserId) {
      order.kamUser = _getKamUser(kamUserId);
    }

    // map Items items.itemId
    if (!isEmpty(orderItems)) {
      order.items = getOrderItems(
        // keyBy(orderItems, 'itemId'),
        orderItems,
        items,
        order.recycler,
        qualityParams,
        order.logisticCost
      );
    }

    order.isAssessmentDone =
      order.items?.findIndex(
        (item) => item.qualityAssessmentStatus !== ASSESSMENT_STATUS.FINALIZED
      ) < 0;

    order.isAssessmentInProgress =
      order.items?.findIndex(
        (item) =>
          item.qualityAssessmentStatus !== ASSESSMENT_STATUS.PENDING &&
          item.qualityAssessmentStatus !== ASSESSMENT_STATUS.FINALIZED
      ) === 0;

    order.isApproved = order.logs?.findIndex((log) => log.toStatus === ORDER_STATUS.APPROVED) >= 0;
    order.isTruckAssigned =
      order.logs?.findIndex((log) => log.toStatus === ORDER_STATUS.TRUCK_ASSIGNED) >= 0;
    order.totalQty = sumBy(order?.items, (it) => it.qty || it.oldQty);

    if (Array.isArray(_.union(order?.items, 'weighbridgeDetails'))) {
      const weighbridgeDetails = _.union(order?.items, 'weighbridgeDetails').find(
        (e) => e.weighBridgeOn === 'PICKED_QTY'
      );
      order.subTotal =
        sumBy(order?.items, 'lineTotal') +
        toRupees(sumBy(order?.items, 'incentivePrice')) *
          Number(weighbridgeDetails?.netWeight || 0 * 100);
    }

    order.total =
      sumBy(order?.items, 'itemTotal') + toRupees(sumBy(order?.items, 'incentivePrice'));
    order.tax = sumBy(order?.items, 'itemTax');
    order.offerSubTotal = sumBy(order?.items, 'offerTotal');
    order.netPayable = order.total + order.logisticCost; // no use
    order.deviationPercentage = sumBy(order.items, 'deviationPercentage');
    order.qualityRisk = sumBy(order.items, 'qualityRisk');
    order.recykalMargin = sumBy(order.items, 'recykalMargin');
    order.recykalMarginPercentage = getRecykalMarginPercentage(
      order.recykalMargin,
      order.offerSubTotal,
      order.logisticCost
    );

    order.weightShortageSum = sumBy(order?.items, 'weightShortage');
    order.weightDeductionSum = sumBy(order?.items, (res) => res?.weightShortage * res?.price);
    order.qualityDeduction =
      percentage(Number(qualityDeduction?.QualityDeduction), order?.subTotal) || 0;
    order.debitNoteWithGst =
      order?.weightDeductionSum +
      order?.qualityDeduction +
      percentage(18, order?.weightDeductionSum + order?.qualityDeduction);
    order.qualityRiskFactor = toFloat((order.qualityRisk * 100) / order.offerSubTotal);
    order.arialDistance = getArialDistance(
      {
        lat: order.pickUpAddress?.lat,
        lng: order.pickUpAddress?.lng,
      },
      {
        lat: order.shippingAddress?.lat,
        lng: order.shippingAddress?.lng,
      }
    );
    order.createdByName = getCreatedByName(order);
    return order;
  }

  function _newOrderMapper(orderData) {
    if (!orderData) return {};
    const order = { ...orderData };

    const { recyclerId, kamUserId, items: orderItems } = order;

    // map recycler purchaseOrder.recyclerId

    const qualityDeduction = order?.recyclerQcParam ? JSON.parse(order?.recyclerQcParam) : null;
    if (recyclerId) {
      order.recycler = _getRecycler(recyclerId);
    }
    order.shippingAddress = orderData.shippingAddress;

    // map kamUser kamUserId
    if (kamUserId) {
      order.kamUser = _getKamUser(kamUserId);
    }
    // map Items items.itemId
    if (!isEmpty(orderItems)) {
      order.items = getOrderItems(
        orderItems,
        items,
        order.recycler,
        qualityParams,
        order.logisticCost
      );
    }

    order.isAssessmentDone =
      order.items?.findIndex(
        (item) => item.qualityAssessmentStatus !== ASSESSMENT_STATUS.FINALIZED
      ) < 0;

    order.isAssessmentInProgress =
      order.items?.findIndex(
        (item) =>
          item.qualityAssessmentStatus !== ASSESSMENT_STATUS.PENDING &&
          item.qualityAssessmentStatus !== ASSESSMENT_STATUS.FINALIZED
      ) === 0;

    order.isApproved = order.logs?.findIndex((log) => log.toStatus === ORDER_STATUS.APPROVED) >= 0;
    order.isTruckAssigned =
      order.logs?.findIndex((log) => log.toStatus === ORDER_STATUS.TRUCK_ASSIGNED) >= 0;
    order.totalQty = sumBy(order?.items, (it) => it.qty || it.oldQty);

    if (Array.isArray(_.union(order?.items, 'weighbridgeDetails'))) {
      const weighbridgeDetails = _.union(order?.items, 'weighbridgeDetails').find(
        (e) => e.weighBridgeOn === 'PICKED_QTY'
      );
      order.subTotal =
        sumBy(order?.items, 'lineTotal') +
        toRupees(sumBy(order?.items, 'incentivePrice')) *
          Number(weighbridgeDetails?.netWeight || 0 * 100);
    }

    order.total =
      sumBy(order?.items, 'itemTotal') + toRupees(sumBy(order?.items, 'incentivePrice'));
    order.tax = sumBy(order?.items, 'itemTax');
    order.offerSubTotal = sumBy(order?.items, 'offerTotal');
    order.netPayable = order.total + order.logisticCost; // no use
    order.deviationPercentage = sumBy(order.items, 'deviationPercentage');
    order.qualityRisk = sumBy(order.items, 'qualityRisk');
    order.recykalMargin = sumBy(order.items, 'recykalMargin');
    order.recykalMarginPercentage = getRecykalMarginPercentage(
      order.recykalMargin,
      order.offerSubTotal,
      order.logisticCost
    );

    order.weightShortageSum = sumBy(order?.items, 'weightShortage');
    order.weightDeductionSum = sumBy(order?.items, (res) => res?.weightShortage * res?.price);
    order.qualityDeduction =
      percentage(Number(qualityDeduction?.QualityDeduction), order?.subTotal) || 0;
    order.debitNoteWithGst =
      order?.weightDeductionSum +
      order?.qualityDeduction +
      percentage(18, order?.weightDeductionSum + order?.qualityDeduction);
    order.qualityRiskFactor = toFloat((order.qualityRisk * 100) / order.offerSubTotal);
    order.arialDistance = getArialDistance(
      {
        lat: order.pickUpAddress?.lat,
        lng: order.pickUpAddress?.lng,
      },
      {
        lat: order.shippingAddress?.lat,
        lng: order.shippingAddress?.lng,
      }
    );
    order.createdByName = getCreatedByName(order);
    return order;
  }

  function orderMapper(orderData) {
    if (isArray(orderData)) {
      return orderData.map((o) => _orderMapper(o));
    }
    return _orderMapper(orderData);
  }

  function newOrderMapper(orderData) {
    if (isArray(orderData)) {
      return orderData.map((o) => _newOrderMapper(o));
    }
    return _newOrderMapper(orderData);
  }

  // requisition mapper
  function alternateRequisitionMapper(requisitionData, _items) {
    if (isArray(requisitionData)) {
      return requisitionData.map((o) => _alternateRequisitionMapper(o, _items)).filter(Boolean);
    }
    return _alternateRequisitionMapper(requisitionData);
  }

  function _alternateRequisitionMapper(requisition, orderItems) {
    if (!requisition) return null;
    const { recyclerId, addressId, items: requisitionItems } = requisition;
    recyclerId ? (requisition.recycler = _getRecycler(recyclerId)) : null;
    addressId ? (requisition.location = _getRecyclerAddress(addressId, recyclerId)) : null;
    // remove items not related to saleOrder
    if (requisitionItems) {
      const itemIds = orderItems.map((o) => o.itemId);
      requisition.items = pick(requisitionItems, itemIds);
      requisition.items = keyBy(
        map(requisition.items, (i) => ({ ...i, item: items[i.itemId] })),
        'itemId'
      );
    }
    return requisition;
  }
  return { orderMapper, alternateRequisitionMapper, newOrderMapper };
}

export function useCommentsMapper() {
  const { kamUsers } = useStakeholders();

  const prepareSO = (_so, truckDetails) => {
    return [];
  };
  async function CommentsMapper(commentList) {
    const values = ROLE_PRIORITY;
    if (isArray(commentList)) {
      const list = await Promise.all(
        commentList?.map(async (comment) => {
          const result = kamUsers?.[comment?.createdBy];
          return { ...comment, role: result?.role };
        })
      );
      return list;
    }
    return prepareSO(commentList);
  }

  return { CommentsMapper };
}
