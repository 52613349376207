import React from 'react';
import { toCapitalize } from '../../utils/Helper';
import Theme from '../../../theme/Theme';
import './CardStyles.scss';

const StatusCard = ({ title, count, footerMsg, onClick }) => {
  return (
    // eslint-disable-next-line
    <div
      className="card card-style"
      style={{ cursor: onClick && 'pointer', borderRadius: '12px' }}
      onClick={onClick}>
      <div className="card-body">
        <div className="d-flex justify-content-between align-items-center w-100">
          <span className="status-card-title" style={{ color: Theme.Colors.text_grey }}>
            {title?.toLowerCase() === 'osv' || title?.toLowerCase() === 'vkyc'
              ? title?.toUpperCase()
              : toCapitalize(title)}
          </span>
          <span
            className="card-count"
            style={{
              color: count === 0 ? Theme.Colors.text_silver : Theme.Colors.text_gray,
            }}>
            {count}
          </span>
        </div>
        {footerMsg && (
          <div className="w-100 mt-3">
            <hr className="footer" />
            <span style={{ color: Theme.Colors.text_gray }}>{footerMsg}</span>
          </div>
        )}
      </div>
    </div>
  );
};

export default StatusCard;
