import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Select from 'react-select';
import useResponsive from '../../hooks/useResponsive';

const DropdownSelect = (inProps) => {
  const {
    value,
    className,
    isInvalid,
    options,
    valueKey = 'id',
    isMulti = false,
    isClearable = false,
    isDisabled,
    isOptionDisabled,
    ...props
  } = inProps;

  const colourStyles = {
    control: (styles) => ({ ...styles, backgroundColor: 'white' }),
    option: (styles, { onClick, isFocused, isSelected }) => {
      return {
        ...styles,
        // eslint-disable-next-line no-nested-ternary
        backgroundColor: isSelected ? 'green' : isFocused || onClick ? '#C9E5B3' : undefined,

        ':active': {
          ...styles[':active'],
          backgroundColor: isSelected ? '#7fd971' : undefined,
        },
      };
    },
  };

  const { isMobile } = useResponsive();

  const selectedValues = isMulti
    ? value
    : value &&
      options?.length > 0 && {
        ...options?.find((o) => o?.[valueKey] === value || o?.[valueKey] === value?.[valueKey]),
      };
  return (
    <Select
      className={classNames(
        'react-select',
        {
          'is-invalid': isInvalid,
        },
        className
      )}
      isMulti={isMulti}
      isClearable={isClearable}
      isDisabled={isDisabled}
      classNamePrefix="select"
      value={selectedValues}
      options={options}
      openMenuOnClick={!isMobile}
      components={{ IndicatorSeparator: () => null }}
      isOptionDisabled={isOptionDisabled}
      styles={colourStyles}
      {...props}
    />
  );
};

DropdownSelect.propTypes = {
  value: PropTypes.any,
  isInvalid: PropTypes.any,
  isDisabled: PropTypes.any,
  valueKey: PropTypes.string,
  options: PropTypes.array,
  onChange: PropTypes.func,
  getOptionLabel: PropTypes.func,
  getOptionValue: PropTypes.func,
};

export default DropdownSelect;
