import React from 'react';
import { Button, Image } from 'react-bootstrap';
import classNames from 'classnames';
import Loader from './Loader/Loader';

export const Empty = ({
  emptyComponent,
  emptyIcon,
  emptyMessage,
  isFiltered,
  emptyResultMessage,
  action,
  actionText,
  hasData,
  className,
  disabled,
}) => (
  <div
    className="text-center no-data-text my-4"
    style={{
      left: '100%',
    }}>
    {emptyIcon && (
      <Image
        className="mb-2"
        width={150}
        src={emptyIcon}
        style={{ display: 'block', margin: '0 auto' }}
      />
    )}

    <div className="font-semibold" style={{ margin: '0 auto', maxWidth: '500px' }}>
      {isFiltered ? emptyResultMessage : emptyMessage || 'No Data Found!'}
    </div>
    {action && !isFiltered && (
      <Button className="dashed-button mt-3" onClick={action} disabled={disabled}>
        {actionText || '+ Add New'}
      </Button>
    )}
  </div>
);
/**
 * HOC for show empty message or child
 * @param loading - show loading
 * @param emptyComponent - show custom empty component
 * @param emptyMessage - message to show when no data
 * @param action - action function to call when click on button
 * @param actionText - text for action button
 * @param children - children when data present
 * @param className - classname
 * @return {JSX.Element}
 * @constructor
 */
const Content = (props) => {
  const {
    loading,
    emptyComponent,
    emptyMessage,
    emptyIcon,
    action,
    actionText,
    children,
    className,
    isFiltered = true,
    hasData,
    text,
    style,
    disabled = false,
  } = props;

  if (loading) {
    return (
      <tr
        className={classNames(
          'align-items-center bg-transparent d-flex flex-column h-50 ' +
            'justify-content-center bg-transparent',
          className
        )}>
        <td colSpan="100%" className="border-0">
          <Loader style={style} text={text || 'Loading'} className="mt-3" />
        </td>
      </tr>
    );
  }
  return hasData ? (
    children
  ) : (
    <tr
      className={classNames(
        'align-items-center bg-transparent d-flex flex-column h-50 ' +
          'justify-content-center bg-transparent',
        className
      )}>
      <td colSpan="100%" className="border-0">
        <Empty {...props} />
      </td>
    </tr>
  );
};

Content.propTypes = {};

export default Content;
