import React from 'react';
import { AsyncPaginate } from 'react-select-async-paginate';
import axios from 'axios';
import { useTransporters } from '../../../hooks/hooks';
import { useAsyncEffect } from '../../../hooks';

const AsyncTransporterPaginationDropdown = (props) => {
  const { page, size, value, setValue, onInputChange, inputValue } = {
    ...props,
  };

  const [
    {
      value: { data: transporterData = [] },
    },
    { doFetchTransportersByName },
  ] = useTransporters();

  async function loadOptions(val) {
    const params = {
      page,
      size,
      bussinessName: val,
    };
    const response = await doFetchTransportersByName(params);
    const aggregators = [];
    if (response) {
      response?.data?.forEach((element) => {
        if (element?.name) {
          aggregators.push({
            label: `${element?.name} `,
            value: element?.id,
          });
        }
      });
    }
    return {
      options: aggregators,
      hasMore: response?.data?.length === 20,
      additional: {
        page: page + 1,
        size: 20,
      },
    };
  }

  useAsyncEffect(async () => {
    if (inputValue !== undefined) {
      await loadOptions(inputValue);
    }
  }, [inputValue]);

  return (
    <AsyncPaginate
      value={value}
      loadOptions={loadOptions}
      onChange={setValue}
      additional={{
        page: 0,
        size: 20,
      }}
      isClearable
      onInputChange={onInputChange}
      inputValue={inputValue}
    />
  );
};

export default AsyncTransporterPaginationDropdown;
