import { find, isEmpty, isNull } from 'lodash';
import React, { Suspense, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Redirect, Route, useHistory, useLocation } from 'react-router-dom';
import AppHeader from './features/AppLayout/AppHeader';
import AppSidebar from './features/AppLayout/AppSidebar';
import MainContent from './features/AppLayout/MainContent';
import './features/AppLayout/_AppLayout.scss';
import * as hooks from './hooks';
import { useAsyncEffect } from './hooks';
import { useAccount, useUserRoles } from './hooks/hooks';
import useAuthCheck from './hooks/useAuthCheck';
import useGroupByModulesPermissions from './hooks/useGroupByModulesPermissions';
import useResponsive from './hooks/useResponsive';
import AppAnalytics from './services/AppAnalytics';
import { fetchAccount } from './services/backend-api';
import { Loader } from './shared/components';
import { DoShow } from './shared/components/GenericComponent';
import { ROLES } from './shared/constants/Constants';
import { userActions } from './store/User/user.reducer';

const LoginPage = React.lazy(() => import('./views/LoginPage/LoginPage'));
const ResetPasswordPage = React.lazy(() => import('./views/ResetPasswordPage/ResetPasswordPage'));

let response = '';
function App() {
  const [collapsed, setCollapsed] = useState(true);
  const [toggled, setToggled] = useState(false);
  const [accountValue, setAccountValue] = useState({});
  const [showAvailabilityModal, setShowAvailabilityModal] = useState(false);
  const { token, loading, refreshToken } = useAuthCheck();
  const { currentAccount } = hooks.useStakeholders();
  const { isMobile } = useResponsive();
  const [{ groupList }] = useGroupByModulesPermissions();
  const [{ valueAllLoginLogoutLogs }, { doCreateLoginLogoutLogs }] = useUserRoles();
  const { logout } = useAccount();
  const history = useHistory();
  const dispatch = useDispatch();
  const location = useLocation();

  const [
    { valueRoleByName, valueValidateToken },
    { doGetRolesByName, doGetValidateToken },
  ] = useUserRoles();

  useEffect(() => {
    AppAnalytics.initialize();
  }, []);

  useAsyncEffect(async () => {
    if (token !== null) {
      response = await fetchAccount();
      dispatch(userActions.setUserDetails(response));

      if (response?.activated === false) {
        logoutHandler();
      }

      const roleName = find(response?.roles, (option) => option !== ROLES?.USER);
      const roleResponse = await doGetRolesByName(roleName);
      const permissionsForRole = roleResponse?.data?.authority?.permissions;
      setAccountValue(response);
      dispatch(userActions.setRoleNamePermissions(permissionsForRole));
    }
  }, []);

  /**
   *  logout the user if his status is inactive
   */
  useAsyncEffect(async () => {
    if (!isNull(refreshToken)) {
      const validateResponse = await doGetValidateToken({
        refresh_token: refreshToken,
        emailId: response?.email,
      });
      if (validateResponse?.data === false) {
        logoutHandler();
      }
    }
  }, [location?.pathname]);

  const logoutHandler = async () => {
    logout();
    await doCreateLoginLogoutLogs({
      logType: 'logout',
      userId: currentAccount?.id,
      isAvailable: currentAccount?.isAvailable === true && 'false',
    });
    history?.push('/login');
    window?.location?.reload(true);
  };

  const handleCollapsedChange = (checked) => setCollapsed(checked);

  const handleToggleSidebar = (value) => {
    if (!collapsed && isMobile) {
      setToggled(false);
      setCollapsed(!collapsed);
    } else {
      setToggled(value);
      setCollapsed(!collapsed);
    }
  };

  if (loading) {
    return <Loader />;
  }

  if (!token) {
    return (
      <Suspense fallback={<Loader />}>
        <div className="login h-100">
          <Route
            name="Login"
            path="/login"
            exact
            render={(props) => {
              return (
                <LoginPage
                  {...props}
                  setAccountValue={setAccountValue}
                  showAvailabilityModal={showAvailabilityModal}
                  setShowAvailabilityModal={setShowAvailabilityModal}
                />
              );
            }}
          />
          <Route
            name="Login"
            path="/reset-password"
            exact
            render={(props) => {
              return token ? <Redirect to="/" /> : <ResetPasswordPage {...props} />;
            }}
          />
          <Route name="Login" path="/set-password" exact component={ResetPasswordPage} />
        </div>
      </Suspense>
    );
  }

  return (
    <DoShow show={!isEmpty(accountValue)}>
      <div className={`app animated fadeIn  ${collapsed ? 'toggled sidebar-collapsed' : ''}`}>
        <AppHeader
          handleToggleSidebar={handleToggleSidebar}
          handleCollapsedChange={handleCollapsedChange}
          toggled={toggled}
          collapsed={collapsed}
        />
        <AppSidebar
          collapsed={collapsed}
          toggled={toggled}
          handleToggleSidebar={handleToggleSidebar}
          groupList={groupList}
        />
        {currentAccount && (
          <MainContent
            groupList={groupList}
            showAvailabilityModal={showAvailabilityModal}
            setShowAvailabilityModal={setShowAvailabilityModal}
          />
        )}
      </div>
    </DoShow>
  );
}

export default App;
