import React from 'react';
import PropTypes from 'prop-types';
import DropdownSelect from '../DropdownSelect';
import { TRACKING_STATUS } from '../../constants/Constants';

const TrackingStatusDropDown = ({ onChange, value, ...props }) => {
  const options = [
    TRACKING_STATUS.PENDING,
    TRACKING_STATUS.INITIATED,
    TRACKING_STATUS.ACCEPTED,
    TRACKING_STATUS.ACTIVE,
    TRACKING_STATUS.COMPLETED,
    TRACKING_STATUS.MANUAL,
  ];
  return (
    <DropdownSelect
      getOptionLabel={(option) => {
        return option.name;
      }}
      formatOptionLabel={(option) => {
        const virtualStyle = {
          borderBottom: '1px solid',
        };
        return <div style={option.isVirtual ? virtualStyle : {}}>{option.name}</div>;
      }}
      onChange={onChange}
      getOptionValue={(option) => option.id}
      options={options}
      value={value}
      maxMenuHeight={150}
      {...props}
    />
  );
};

TrackingStatusDropDown.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func,
};

export default TrackingStatusDropDown;
