import React, { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import {
  CREATE_LABEL_LOOKUP,
  LOWERCASE_REGEX,
  NUMBER_REGEX,
  PROGRESS_COLOR_LOOKUP,
  SPECIALCHARACTERS_REGEX,
  UPPERCASE_REGEX,
} from '../../shared/constants/Constants';

const PasswordStrengthMeter = (props) => {
  const { passwordValue, passwordCriteriaCount, setPasswordCriteriaCount } = props;
  const [numWidth, setNumWidth] = useState(0);

  useEffect(() => {
    let passwordStrengthCount = 0;
    if (passwordValue?.length >= 8) {
      passwordStrengthCount += 1;
    }
    if (new RegExp(LOWERCASE_REGEX).test(passwordValue)) {
      passwordStrengthCount += 1;
    }
    if (new RegExp(UPPERCASE_REGEX).test(passwordValue)) {
      passwordStrengthCount += 1;
    }
    if (new RegExp(NUMBER_REGEX).test(passwordValue)) {
      passwordStrengthCount += 1;
    }
    if (new RegExp(SPECIALCHARACTERS_REGEX).test(passwordValue)) {
      passwordStrengthCount += 1;
    }
    setNumWidth(parseInt(passwordStrengthCount * 100) / 5);
    setPasswordCriteriaCount(passwordStrengthCount);
  }, [passwordValue]);

  const createPassLabel = CREATE_LABEL_LOOKUP[passwordCriteriaCount] || '';
  const progressColor = PROGRESS_COLOR_LOOKUP[passwordCriteriaCount] || '';

  const changePasswordColor = {
    width: `${numWidth}%`,
    background: progressColor,
    height: '7px',
  };

  return (
    <>
      <div className="progress" style={{ height: '7px' }}>
        <div className="progress-bar" style={changePasswordColor} />
      </div>

      <Row>
        <Col xs="6" className="d-flex align-items-center">
          <p>
            <b>Password Strength</b>
          </p>
        </Col>
        <Col xs="6" className="text-right">
          <p className="text-right" style={{ color: progressColor }}>
            {createPassLabel}
          </p>
        </Col>
      </Row>
    </>
  );
};

export default PasswordStrengthMeter;
