/* eslint-disable no-nested-ternary */
import { filter, includes } from 'lodash';
import React from 'react';
import PropTypes from 'prop-types';
import DropdownSelect from '../DropdownSelect';
import { useCatalog, useStakeholders } from '../../../hooks';
import { allItems } from '../../constants/Constants';

const ItemDropdown = ({
  categoryId,
  excludeItemIds,
  isAllItem,
  isListingSOItems = false,
  materialTypeId,
  isHsnCode = false,
  ...props
}) => {
  const { itemsFlat = [], materialNameGroupedItems } = useCatalog();
  const { currentAccount } = useStakeholders();
  const allItemsUnderMaterials = materialNameGroupedItems?.[materialTypeId];
  const _allItemsUnderMaterials = allItemsUnderMaterials?.filter((option) => option?.active);
  const splittedCategory = currentAccount?.category?.split(',');
  const createRequisition = isAllItem ? [...itemsFlat] : [allItems, ...itemsFlat];

  const filterItems =
    categoryId !== undefined
      ? itemsFlat?.filter((item) => item?.category?.id === categoryId)
      : createRequisition;

  // if it is Listing PWA -> all items under material
  // if the material type in account is All-> showing all item
  // if the material type in account is specific-> showing specific items only.
  const filterItemBasicOptions = isListingSOItems
    ? _allItemsUnderMaterials
    : !includes(splittedCategory, 'All')
    ? filter(filterItems, (option) => {
        return includes(splittedCategory, option?.categoryDTO?.materialType?.name);
      })
    : filterItems;

  return (
    <DropdownSelect
      options={filterItemBasicOptions}
      getOptionLabel={(option) => {
        return isHsnCode ? (
          <>
            <span>{option?.name}</span>
            <br />
            <span>HSN Code - {option?.hsn !== null ? `#${option?.hsn}` : 'NA'}</span>
          </>
        ) : (
          option?.name
        );
      }}
      getOptionValue={(option) => {
        return isHsnCode ? `${option?.name}-${option?.hsn}` : option?.id;
      }}
      {...props}
    />
  );
};

ItemDropdown.propTypes = {
  categoryId: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func,
};

export default ItemDropdown;
