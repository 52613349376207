import axios from 'axios';
import { useState } from 'react';

// Hook to cancel the previous api call
export function useApiFetch() {
  const [newArray, setNewArray] = useState(axios.CancelToken.source());
  let cancelToken;
  const doFetchApiWithCancelPreviousCall = async (params, doFetch) => {
    if (typeof cancelToken !== typeof undefined) {
      cancelToken.cancel('Operation canceled due to new request.');
    }
    cancelToken = axios.CancelToken.source();

    const fetchPost = async () => {
      let apiData = null;
      try {
        apiData = await doFetch({
          data: params,
          source: newArray,
        });
      } catch (err) {
        ('There was a problem or request was cancelled.');
      }
      return apiData;
    };
    return fetchPost();
  };
  return { doFetchApiWithCancelPreviousCall };
}
