import React from 'react';
import { Image, Modal } from 'react-bootstrap';
import styled from 'styled-components';
import moment from 'moment';

import CreateBtn from '../../../assets/img/add_btn.svg';
import EditBtn from '../../../assets/img/edit_btn.svg';
import './SegmentsLogs.scss';

const StyleModal = styled(Modal)`
  .modal-dialog {
    border-radius: 20px;
  }
`;

const SegmentsLogs = ({ show, showState, logs }) => {
  function parseJSON(jsonString) {
    try {
      return JSON.parse(jsonString);
    } catch (error) {
      return {};
    }
  }

  const checkNotLimit = (maxValue) => {
    if (maxValue === '999999.0') {
      return 'No Limit';
    }
    return maxValue;
  };

  const extractData = (jsonObj) => {
    const subLogs = [];
    const segmentLog = parseJSON(jsonObj);
    if (!segmentLog) return [];
    // updated old values
    if ('oldQtyMaxValue' in segmentLog && 'newQtyMaxValue' in segmentLog) {
      subLogs.push(
        `Quantity Condition Max value Changed from  
      ${checkNotLimit(segmentLog?.oldQtyMaxValue)} to ${checkNotLimit(segmentLog?.newQtyMaxValue)}.`
      );
    }
    if ('oldQtyMinValue' in segmentLog && 'newQtyMinValue' in segmentLog) {
      subLogs.push(
        `Quantity Condition Min value Changed from  
        ${segmentLog?.oldQtyMinValue} to ${segmentLog?.newQtyMinValue}.`
      );
    }
    if ('oldTxnMaxValue' in segmentLog && 'newTxnMaxValue' in segmentLog) {
      subLogs.push(
        `Transactions Condition Max value Changed from  
        ${checkNotLimit(segmentLog?.oldTxnMaxValue)} to ${checkNotLimit(
          segmentLog?.newTxnMaxValue
        )}.`
      );
    }
    if ('oldTxnMinValue' in segmentLog && 'newTxnMinValue' in segmentLog) {
      subLogs.push(
        `Transactions Condition Min value Changed from  
        ${segmentLog?.oldTxnMinValue} to ${checkNotLimit(segmentLog?.newTxnMinValue)}.`
      );
    }
    if ('oldCashChallanMinValue' in segmentLog && 'newCashChallanMinValue' in segmentLog) {
      subLogs.push(
        `Cash Challan Condition Min value Changed from  
        ${segmentLog?.oldCashChallanMinValue} to ${segmentLog?.newCashChallanMinValue}.`
      );
    }
    if ('oldCashChallanMaxValue' in segmentLog && 'newCashChallanMaxValue' in segmentLog) {
      subLogs.push(
        `Cash Challan Condition Max value Changed from 
        ${checkNotLimit(segmentLog?.oldCashChallanMaxValue)} to ${checkNotLimit(
          segmentLog?.newCashChallanMaxValue
        )}.`
      );
    }
    if ('oldMarginMaxValue' in segmentLog && 'newMarginMaxValue' in segmentLog) {
      subLogs.push(
        `Margin Condition Max value Changed from  
        ${checkNotLimit(segmentLog?.oldMarginMaxValue)} to ${checkNotLimit(
          segmentLog?.newMarginMaxValue
        )}.`
      );
    }
    if ('oldMarginMinValue' in segmentLog && 'newMarginMinValue' in segmentLog) {
      subLogs.push(
        `Margin Condition Min value Changed from  
        ${segmentLog?.oldMarginMinValue} to ${segmentLog?.newMarginMinValue}.`
      );
    }
    if ('oldSellerRatingMaxValue' in segmentLog && 'newSellerRatingMaxValue' in segmentLog) {
      subLogs.push(
        `SellerRating Condition Max value Changed from  
        ${checkNotLimit(segmentLog?.oldSellerRatingMaxValue)} to ${checkNotLimit(
          segmentLog?.newSellerRatingMaxValue
        )}.`
      );
    }
    if ('oldSellerRatingMinValue' in segmentLog && 'newSellerRatingMinValue' in segmentLog) {
      subLogs.push(
        `SellerRating Condition Min value Changed from  
        ${segmentLog?.oldSellerRatingMinValue} to ${segmentLog?.newSellerRatingMinValue}.`
      );
    }

    return subLogs;
  };

  const extractBaseSegment = (jsonObj) => {
    const subLogs = [];
    const segmentLog = parseJSON(jsonObj);
    if (!segmentLog) return [];
    // segments page updates
    if ('oldSegmentInterValType' in segmentLog && 'newSegmentIntervalType' in segmentLog) {
      subLogs.push(
        `Recalculation changed from 
        ${segmentLog?.oldSegmentInterValType} to ${segmentLog?.newSegmentIntervalType}.`
      );
    }
    if ('oldSegmentAggregatorType' in segmentLog && 'newSegmentAggregatorType' in segmentLog) {
      subLogs.push(
        `Aggregator Type changed from 
        ${segmentLog?.oldSegmentAggregatorType} to ${segmentLog?.newSegmentAggregatorType}.`
      );
    }
    if ('newSegmentMaterialType' in segmentLog && 'oldSegmentMaterialType' in segmentLog) {
      subLogs.push(
        `Material Type changed from 
        ${segmentLog?.oldSegmentMaterialType} to ${segmentLog?.newSegmentMaterialType}.`
      );
    }

    return subLogs;
  };

  const extractNewSegment = (jsonObj) => {
    const subLogs = [];
    const segmentLog = parseJSON(jsonObj);
    if (!segmentLog) return [];
    if ('segmentName' in segmentLog) {
      subLogs.push(`Added new segment ${segmentLog?.segmentName}.`);
    }
    if ('newQtyMinValue' in segmentLog && 'newQtyMaxValue' in segmentLog) {
      subLogs.push(
        `Added new Quantity condition Min and Max value 
        ${segmentLog?.newQtyMinValue} to ${checkNotLimit(segmentLog?.newQtyMaxValue)}.`
      );
    }
    if ('newMarginMinValue' in segmentLog && 'newMarginMaxValue' in segmentLog) {
      subLogs.push(
        `Added new Margin condition Min and Max value 
        ${segmentLog?.newMarginMinValue} to ${checkNotLimit(segmentLog?.newMarginMaxValue)}.`
      );
    }
    if ('newTxnMinValue' in segmentLog && 'newTxnMaxValue' in segmentLog) {
      subLogs.push(
        `Added new Transaction condition Min and Max value 
        ${segmentLog?.newTxnMinValue} to ${checkNotLimit(segmentLog?.newTxnMaxValue)}.`
      );
    }
    if ('newSellerRatingMinValue' in segmentLog && 'newSellerRatingMinValue' in segmentLog) {
      subLogs.push(
        `Added new Seller rating condition Min and Max value 
        ${segmentLog?.newSellerRatingMinValue} to ${checkNotLimit(
          segmentLog?.newSellerRatingMinValue
        )}.`
      );
    }
    if ('newCashChallanMinValue' in segmentLog && 'newCashChallanMaxValue' in segmentLog) {
      subLogs.push(
        `Added new Cash Challan condition Min and Max value 
        ${segmentLog?.newCashChallanMinValue} to ${checkNotLimit(
          segmentLog?.newCashChallanMaxValue
        )}.`
      );
    }
    return subLogs;
  };

  const logsValues = () => {
    if (!logs) return [];

    return logs?.data?.map((item) => {
      const logsObject = {};

      if (item.logType === 'ADD_BASE_SEGMENT_DETAILS') {
        logsObject.logType = 'SEGMENT CREATE';
        logsObject.createdBy = item?.createdUser;
        logsObject.createdDate = item?.createdAt;
        logsObject.subLogs = [];
        logsObject.img = CreateBtn;
        logsObject.titleColor = '#079455';
      }
      if (item?.logType === 'UPDATE_BASE_SEGMENT_DETAILS') {
        logsObject.logType = 'SEGMENT EDIT';
        logsObject.createdBy = item?.createdUser;
        logsObject.createdDate = item?.createdAt;
        logsObject.subLogs = extractBaseSegment(item?.json);
        logsObject.img = EditBtn;
        logsObject.titleColor = '#DC6803';
      }
      if (item?.logType === 'ADD_SEGMENT_NEW_VALUE') {
        logsObject.logType = 'SEGMENT EDIT';
        logsObject.createdBy = item?.createdUser;
        logsObject.createdDate = item?.createdAt;
        logsObject.subLogs = extractNewSegment(item?.json);
        logsObject.img = EditBtn;
        logsObject.titleColor = '#DC6803';
      }
      if (item?.logType === 'UPDATE_SEGMENT_VALUE') {
        logsObject.logType = 'SEGMENT EDIT';
        logsObject.createdBy = item?.createdUser;
        logsObject.createdDate = item?.createdAt;
        logsObject.subLogs = extractData(item?.json);
        logsObject.img = EditBtn;
        logsObject.titleColor = '#DC6803';
      }
      return logsObject;
    });
  };

  return (
    <>
      <StyleModal show={show} onHide={showState} size="xl" animation>
        <Modal.Header closeButton>
          <Modal.Title>Plastic/Aggregator Logs</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {logsValues()?.map((item, index) => (
            <>
              <div className="SegmentLogs d-flex">
                <div className="pl-2 pr-2">
                  <Image
                    className="d-flex align-self-center"
                    src={item?.img}
                    height={50}
                    width={50}
                  />
                  {index < logsValues().length - 1 && (
                    <div className="d-flex justify-content-center btn-border-sty">
                      <div style={{ width: '1px', backgroundColor: '#667085' }} />
                    </div>
                  )}
                </div>
                <div>
                  <div className="title-txt-sty" style={{ color: item?.titleColor }}>
                    {item?.logType}
                  </div>
                  <div className="txt-font-size">{item?.createdBy}</div>
                  <div className="txt-font-size">
                    {moment(new Date(item?.createdDate)).format('DD-MMM-YYYY')}
                  </div>
                  <ul>
                    {item?.subLogs?.map((value, i) => (
                      <li key={i} className="sub-list">
                        {value}
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </>
          ))}
        </Modal.Body>
      </StyleModal>
    </>
  );
};

export default SegmentsLogs;
