/* eslint-disable react/react-in-jsx-scope */
import LoadingOverlay from 'react-loading-overlay';
import PropTypes from 'prop-types';
import Loader from './Loader/Loader';

const OverLayLoader = ({ active = false, children, text, ...restProps }) => {
  return (
    <>
      <LoadingOverlay
        active={active}
        spinner={<Loader text=" " />}
        text={text || 'Loading...'}
        {...restProps}
        styles={{
          overlay: (base) => ({
            ...base,
            background: 'rgba(255, 255, 255, 0.7)',
            color: 'green',
          }),
          content: (base) => ({
            ...base,
          }),
          wrapper: (base) => ({
            ...base,
            overflow: 'scroll',
          }),
        }}>
        {children}
      </LoadingOverlay>
    </>
  );
};

OverLayLoader.propTypes = {
  children: PropTypes.any,
  text: PropTypes.string,
  active: PropTypes.bool,
};

export default OverLayLoader;
