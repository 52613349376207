/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import classNames from 'classnames';
import React from 'react';
import { Col, Form, FormControl, Image, Row } from 'react-bootstrap';
import Theme from '../../theme/Theme';

const DisplayField = ({
  hide,
  children,
  label,
  value,
  valueClass,
  labelStyle,
  valueStyle,
  labelClass,
  isInputForm = false,
  icon = false,
  iconLabel,
  imageSrc = false,
  isDocument = false,
  iconLabelStyle,
  onClick,
  isHorizontal = false,
}) => {
  return (
    !hide && (
      <div style={{ padding: '0.4rem 0' }}>
        {isHorizontal && (
          <div>
            <Row>
              <Col>
                <span
                  style={{
                    fontSize: '14px',
                    fontFamily: 'font-semibold',
                    color: Theme.Colors.display_label,
                    ...labelStyle,
                  }}
                  className={labelClass}>
                  {label}
                </span>
              </Col>

              <Col className="text-right">
                <span
                  style={{
                    fontSize: '14px',
                    color: Theme.Colors.display_value,
                    ...valueStyle,
                  }}
                  className={(classNames('text-dark font-semibold'), valueClass)}>
                  {value}
                </span>
              </Col>
            </Row>
          </div>
        )}

        {!isHorizontal && label && (
          <Form.Label
            style={{
              letterSpacing: '0.5px',
              fontSize: '12px',
              fontFamily: 'font-semibold',
              marginBottom: '2px',
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              ...labelStyle,
            }}
            className={labelClass}>
            <span>{label}</span>
            {icon && (
              <span style={{ float: 'right' }} onClick={onClick}>
                {imageSrc && <span>{imageSrc}</span>}
                <span style={iconLabelStyle}>{iconLabel}</span>
              </span>
            )}
          </Form.Label>
        )}
        {isInputForm && (
          <Form.Control
            value={value}
            autoComplete="off"
            style={{ fontSize: '14px', ...valueStyle }}
            className={valueClass}
            onChange={() => {}}
          />
        )}
        {!isInputForm && !isHorizontal && (
          <div
            style={{ fontSize: '14px', ...valueStyle }}
            className={classNames('text-dark font-bold')}>
            {children || value}
          </div>
        )}
      </div>
    )
  );
};
export default DisplayField;
