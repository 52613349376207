import { RiMenuFoldLine } from '@react-icons/all-files/ri/RiMenuFoldLine';
import { RiMenuUnfoldLine } from '@react-icons/all-files/ri/RiMenuUnfoldLine';

import React, { useRef, useState } from 'react';
import {
  Button,
  Col,
  Image,
  ListGroup,
  Navbar,
  OverlayTrigger,
  Popover,
  Row,
} from 'react-bootstrap';

// eslint-disable-next-line import/no-named-as-default-member
import NotificationsBellIcon from '../../shared/components/Notifications/NotificationsBellIcon';
import AccountIcon from '../../assets/img/account_icon.png';
import ChangePasswordIcon from '../../assets/img/change_password.png';
import Logo from '../../assets/img/NewLogo.svg';
import LogoutIcon from '../../assets/img/logout.png';
import ProfileIcon from '../../assets/img/user_profile.png';
import { useStakeholders } from '../../hooks';
import { useAccount, useUserRoles } from '../../hooks/hooks';

import UserChangePassword from '../User/UserChangePassword';
import { PromptModal } from '../../shared/components';
import UserProfileModal from '../User/UserProfileModal';
import EditOverlayPopver from '../../shared/components/EditOverlayPopver';
import useResponsive from '../../hooks/useResponsive';
import { PrimaryButton } from '../../shared/components/GenericComponent';

const AppHeader = ({ handleToggleSidebar, collapsed }) => {
  const [showProfile, setShowProfile] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const { currentAccount } = useStakeholders();
  const { isMobile } = useResponsive();
  const { logout } = useAccount();
  const [showConfirmationSignOut, setShowConfirmationSignout] = useState(false);
  const target = useRef(null);
  const [{ valueAllLoginLogoutLogs }, { doCreateLoginLogoutLogs }] = useUserRoles();

  return (
    <Navbar className="AppHeader" fixed="top" expand="lg">
      <Row style={{ width: '103%' }}>
        <Col className="pr-0">
          <Button
            variant="link"
            className="pl-0 mt-1 text-dark float-left pr-3"
            onClick={handleToggleSidebar}>
            {collapsed ? (
              <RiMenuUnfoldLine className="text-xlarge" />
            ) : (
              <RiMenuFoldLine className="text-xlarge" />
            )}
          </Button>
          <Image
            src={Logo}
            className="float-left mr-2"
            style={{ width: isMobile ? '85px' : '110px', height: '30px', marginTop: '10px' }}
          />
          {/* <Navbar.Brand href="#home" className="float-left mt-3 app-name">
            <div className="business-name float-left font-regular" style={{ color: '#707070' }}>
              Marketplace
            </div>
          </Navbar.Brand> */}
        </Col>
        <Col>
          <OverlayTrigger
            trigger="click"
            placement="bottom-end"
            rootClose
            target={target.current}
            overlay={
              <Popover id="popover-contained">
                <Popover.Content>
                  <ListGroup variant="flush" defaultActiveKey="#link1">
                    <ListGroup.Item
                      onClick={() => {
                        document.body.click();
                        setShowProfile(true);
                      }}
                      className="text-regular"
                      action>
                      <Image
                        src={ProfileIcon}
                        className="mr-2"
                        style={{ width: '15px', height: '15px' }}
                      />
                      Profile
                    </ListGroup.Item>
                    <ListGroup.Item
                      onClick={() => {
                        document.body.click();
                        setShowPassword(true);
                      }}
                      className="text-regular"
                      action>
                      <Image
                        src={ChangePasswordIcon}
                        className="mr-2"
                        style={{ width: '15px', height: '15px' }}
                      />
                      Change Password
                    </ListGroup.Item>
                    <ListGroup.Item
                      onClick={() => {
                        document.body.click();
                        setShowConfirmationSignout(true);
                      }}
                      className="text-regular"
                      action>
                      <Image
                        src={LogoutIcon}
                        className="mr-2"
                        style={{ width: '15px', height: '15px' }}
                      />
                      Sign Out
                    </ListGroup.Item>
                  </ListGroup>
                </Popover.Content>
              </Popover>
            }>
            <span className="pointer text-medium float-right mt-2" style={{ color: '#607581' }}>
              {!isMobile && currentAccount?.firstName}
              <Image
                ref={target}
                src={AccountIcon}
                style={{ width: '30px', height: '30px' }}
                className="ml-2"
              />
            </span>
          </OverlayTrigger>
          {/* <PrimaryButton
              label="Create Ticket"
              size="sm"
              className="font-semibold"
              disabled
              onClick={() => {
                window.open('https://recykalmarketplace.zohodesk.in/portal/en/home');
              }}
              buttonStyle={{ marginLeft: '430px', marginTop: '7px' }}
            /> */}

          {/* <NotificationsBellIcon /> */}
        </Col>
      </Row>
      <UserProfileModal show={showProfile} setShow={setShowProfile} account={currentAccount} />

      <UserChangePassword show={showPassword} setShow={setShowPassword} account={currentAccount} />

      <PromptModal
        title="LogOut"
        text="Are you sure you want to logout?"
        dismissBtnText="Cancel"
        successBtnText="Confirm"
        successBtnClass="btn-primary"
        onSuccess={async () => {
          logout();
          setShowConfirmationSignout(false);
          await doCreateLoginLogoutLogs({
            logType: 'logout',
            userId: currentAccount?.id,
            isAvailable: currentAccount?.isAvailable === true && 'false',
            locationDetails: {},
          });
        }}
        show={showConfirmationSignOut}
        onDismiss={() => setShowConfirmationSignout(false)}
      />
    </Navbar>
  );
};

AppHeader.propTypes = {};

export default AppHeader;
