import React from 'react';
import PropTypes from 'prop-types';
import DropdownSelect from '../DropdownSelect';
import { PAYMENT_GST_STATUS } from '../../constants/Constants';

const GstPaymentStatusDropdown = ({ onChange, value, ...props }) => {
  return (
    <DropdownSelect
      formatOptionLabel={(option) => {
        const virtualStyle = {
          borderBottom: '1px solid',
        };
        return <div style={option.isVirtual ? virtualStyle : {}}>{option.label}</div>;
      }}
      onChange={onChange}
      getOptionLabel={(option) => option.label}
      getOptionValue={(option) => option.id}
      options={PAYMENT_GST_STATUS}
      value={value}
      {...props}
    />
  );
};

GstPaymentStatusDropdown.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func,
};

export default GstPaymentStatusDropdown;
