import React, { useEffect } from 'react';
import axios from 'axios';
import { useDispatch } from 'react-redux';
import ls from 'localstorage-slim';
import { useHistory } from 'react-router-dom';
import { userActions } from '../store/User/user.reducer';
import { useAccount } from './hooks';
import { authActions } from '../store/Auth/auth.reducer';

const useAuthCheck = () => {
  const history = useHistory();
  const { accountDetails, token, logout, loading, refreshToken } = useAccount();
  const dispatch = useDispatch();

  /**
   * Saving the account details in redux store
   */
  useEffect(() => {
    const handleOtherBrowserLogin = (e) => {
      if (e.key === 'userToken' && !e.oldValue && e.newValue) {
        // Your reload logic here
        window.location.reload();
      }
    };

    if (token) {
      // Set the bearer token in headers
      axios.defaults.headers.common.Authorization = `Bearer ${token}`;
      const accountDetailsData = ls.get('accountDetails', { decrypt: true });
      dispatch(userActions.setUserDetails(accountDetailsData));
      dispatch(authActions.setToken(token));
    }
    if (refreshToken) dispatch(authActions.setRefreshToken(refreshToken));

    window.addEventListener('storage', handleOtherBrowserLogin);
    return function cleanup() {
      window.removeEventListener('storage', handleOtherBrowserLogin);
    };
  }, [token, refreshToken]);

  useEffect(() => {
    const handleOtherBrowserLogout = (e) => {
      if (e.key === 'userToken' && e.oldValue && !e.newValue) {
        // Your logout logic here
        logout();
      }
    };
    window.addEventListener('storage', handleOtherBrowserLogout);
    return function cleanup() {
      window.removeEventListener('storage', handleOtherBrowserLogout);
    };
  }, [logout]);

  useEffect(() => {
    const { search } = window.location;
    const params = new URLSearchParams(search);
    if (!token) {
      if (
        window.location.pathname !== '/reset-password' &&
        window.location.pathname !== '/set-password'
      ) {
        if (!params.get('key')) {
          history.push('/login');
        } else {
          history.push(`/reset-password?key=${params.get('key')}`);
        }
      }
      logout();
    } else if (window.location.pathname === '/reset-password') {
      if (params.get('key')) {
        history.push('/');
      }
    } else if (window.location.pathname === '/set-password') {
      if (params.get('key')) {
        history.push('/');
      }
    }
  }, [accountDetails, token]);

  return { accountDetails, token, loading, refreshToken };
};

export default useAuthCheck;
