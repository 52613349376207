import { FaEllipsisV } from '@react-icons/all-files/fa/FaEllipsisV';
import React from 'react';
import { ListGroup, OverlayTrigger, Popover } from 'react-bootstrap';

// eslint-disable-next-line max-len
// https://stackoverflow.com/questions/49706823/what-is-this-props-children-and-when-you-should-use-it

const EditOverlayPopver = ({ children }) => {
  return (
    <>
      <OverlayTrigger
        trigger="click"
        key="right"
        placement="left"
        rootClose
        overlay={
          <Popover>
            <Popover.Content style={{ cursor: 'pointer' }}>
              <ListGroup variant="flush" defaultActiveKey="#link1" style={{ cursor: 'pointer' }}>
                {children}
              </ListGroup>
            </Popover.Content>
          </Popover>
        }>
        <FaEllipsisV className="float-right" />
      </OverlayTrigger>
    </>
  );
};

export default EditOverlayPopver;
