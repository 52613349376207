import React from 'react';
import PropTypes from 'prop-types';
import DropdownSelect from '../DropdownSelect';
import { useCatalog, useRegion } from '../../../hooks';

const StateDropdownFilter = ({ id, excludeItemIds, ...props }) => {
  const { statesFlat } = useRegion();
  const arr2 = [{ name: 'All states', id: 'all' }];
  const combine2 = arr2.concat(statesFlat);
  const filterItems = id !== undefined ? combine2.filter((item) => item?.id === id) : combine2;
  return (
    <DropdownSelect
      options={filterItems}
      getOptionLabel={(option) => option.name}
      getOptionValue={(option) => option.id}
      {...props}
    />
  );
};

StateDropdownFilter.propTypes = {
  id: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func,
};

function arePropsEqual(prev, next) {
  return prev.id === next.id || prev.value === next.value;
}
export default StateDropdownFilter;
