import React from 'react';
import PropTypes from 'prop-types';
import DropdownSelect from '../DropdownSelect';
import { QUOTES_STATUS, SALESORDER_STATUS } from '../../constants/Constants';

const KamRequisitionStatusDropdown = ({ onChange, value, ...props }) => {
  const options = [
    SALESORDER_STATUS.IN_REVIEW,
    SALESORDER_STATUS.APPROVED,
    SALESORDER_STATUS.FULFILLED,
    SALESORDER_STATUS.REJECTED,
    SALESORDER_STATUS.CLOSED,
    SALESORDER_STATUS.IN_PROGRESS,
  ];

  return (
    <DropdownSelect
      formatOptionLabel={(option) => {
        const virtualStyle = {
          borderBottom: '1px solid',
        };
        return <div style={option.isVirtual ? virtualStyle : {}}>{option.name}</div>;
      }}
      onChange={onChange}
      getOptionValue={(option) => option.id}
      getOptionLabel={(option) => option.name}
      options={options}
      value={value}
      {...props}
    />
  );
};

KamRequisitionStatusDropdown.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func,
};

export default KamRequisitionStatusDropdown;
