import React from 'react';
import PropTypes from 'prop-types';
import DropdownSelect from '../DropdownSelect';
import { PAYMENT_MATERIAL_STATUS } from '../../constants/Constants';

const MaterialPaymentStatusDropdown = ({ onChange, value, ...props }) => {
  const paymentMaterialStatusOptions = PAYMENT_MATERIAL_STATUS.filter((option) => {
    return option?.id !== 'Processing';
  });
  return (
    <DropdownSelect
      formatOptionLabel={(option) => {
        const virtualStyle = {
          borderBottom: '1px solid',
        };
        return <div style={option.isVirtual ? virtualStyle : {}}>{option.label}</div>;
      }}
      onChange={onChange}
      getOptionLabel={(option) => option.label}
      getOptionValue={(option) => option.id}
      options={paymentMaterialStatusOptions}
      value={value}
      {...props}
    />
  );
};

MaterialPaymentStatusDropdown.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func,
};

export default MaterialPaymentStatusDropdown;
