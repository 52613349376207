/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { useState } from 'react';
import { Card, Form } from 'react-bootstrap';
import { useFormikContext } from 'formik';
import { SecondaryButton } from '../../shared/components/GenericComponent';
import AddIcon from '../../assets/img/plus_circle.svg';
import { FormField } from '../../shared/components';
import MinMaxCard from './MinMaxCard/MinMaxCard';
import { DeleteIcon } from '../../assets/img/imageExport/images';
import { displayToastMessage } from '../../shared/utils/Helper';
import { TOAST_TYPES } from '../../shared/constants/Constants';
import { ErrorCode } from '../../shared/constants';

const SegmentationDetail = ({ verticalDetails }) => {
  const { errors, values, setFieldValue, touched } = useFormikContext();
  const getVerticalDetails = () => {
    return verticalDetails?.condition?.map((item) => ({
      valueType: item.value,
      minValue: '',
      maxValue: '',
    }));
  };

  const addSegmentation = () => {
    setFieldValue('vendorSegmentValues', [
      ...values.vendorSegmentValues,
      { segmentName: '', valueTypes: getVerticalDetails(), active: true },
    ]);
  };

  const removeSegmentation = (removeId, removeIndex) => {
    if (values.vendorSegmentValues?.filter((item) => item?.active).length === 1) {
      displayToastMessage(TOAST_TYPES?.ERROR, ErrorCode?.SEGMENT_DELETE);
      return;
    }
    if (removeId === undefined) {
      setFieldValue(
        'vendorSegmentValues',
        values.vendorSegmentValues.filter((_, index) => index !== removeIndex)
      );
      return;
    }
    setFieldValue(
      'vendorSegmentValues',
      values.vendorSegmentValues.map((item, index) => {
        if (item?.id === removeId) {
          return { ...item, active: false };
        }
        return item;
      })
    );
  };

  return (
    <>
      <hr />
      <div className="d-flex justify-content-between align-item-center">
        <h5 className="mb-3 mt-3 p-2">Segmentation Conditions</h5>
        <SecondaryButton
          label="Add Segment"
          buttonStyle={{
            borderColor: 'green',
            borderWidth: '1px',
            height: '40px',
            color: 'green',
            borderRadius: '10px',
          }}
          imageStyle={{ marginRight: '5px' }}
          src={AddIcon}
          onClick={addSegmentation}
        />
      </div>
      <hr />
      <div>
        {values?.vendorSegmentValues?.map((item, i) => (
          <>
            {item?.active !== false ? (
              <div style={{ marginTop: '15px' }}>
                <Card style={{ borderRadius: '10px' }}>
                  <Card.Body style={{ backgroundColor: '#F6F6F6', borderRadius: '10px' }}>
                    <Form>
                      <Form.Group className="mb-3">
                        <div>
                          <div className="d-flex justify-content-between">
                            <div className="d-flex" style={{ marginRight: '10px', width: '100%' }}>
                              <Form.Label className="width: auto" style={{ alignSelf: 'center' }}>
                                Segment Name :
                              </Form.Label>
                              <div style={{ width: '40%', marginLeft: '10px' }}>
                                <FormField
                                  style={{
                                    width: '100%',
                                    marginLeft: '10px',
                                    borderColor: '#D0D5DD',
                                  }}
                                  labelClass="required"
                                  placeholder="Enter Segment Name"
                                  name={`vendorSegmentValues[${i}].segmentName`}
                                  value={values.vendorSegmentValues[i].segmentName}
                                  onChange={(e) => {
                                    setFieldValue(
                                      `vendorSegmentValues[${i}].segmentName`,
                                      e.target.value
                                    );
                                  }}
                                />
                                <Form.Control.Feedback type="isInvalid">
                                  <span style={{ color: '#ff7272' }}>
                                    {touched?.vendorSegmentValues?.[i]?.segmentName &&
                                      errors?.vendorSegmentValues?.[i]?.segmentName}
                                  </span>
                                </Form.Control.Feedback>
                              </div>
                            </div>
                            {/* {!values?.vendorSegmentValues?.[0]?.id && ( */}
                            <DeleteIcon
                              height="2rem"
                              onClick={() => removeSegmentation(item?.id, i)}
                            />
                            {/* )} */}
                          </div>

                          <hr />
                          <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                            <>
                              {item?.valueTypes?.map((condition, index) => (
                                <div className="d-block" style={{ width: '50%' }}>
                                  <MinMaxCard
                                    title={condition.valueType}
                                    index={index}
                                    parentIndex={i}
                                  />
                                </div>
                              ))}
                            </>
                          </div>
                        </div>
                      </Form.Group>
                    </Form>
                  </Card.Body>
                </Card>
              </div>
            ) : null}
          </>
        ))}
      </div>
    </>
  );
};

export default SegmentationDetail;
