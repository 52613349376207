export const ALL_PERMISSIONS = {
  FULL_ACCESS: 'full_access',
  VIEW: 'can_view',
  ADD: 'can_add',
  EDIT: 'can_edit',
  CREATE: 'can_create',
  TRANSPORTER_MANUAL_PAYMENT: 'create_transporter_manual_payment',
  DOWNLOAD_GST_FILING: 'download_gst_filing_status',
  FETCH_GST_FILING: 'fetch_gst_filing_status',
  DOWNLOAD_GST_RECONCILIATION: 'download_gst_reconcilation',
  FETCH_GST_RECONCILIATION: 'fetch_gst_reconcilation',
  TDS_MAKE_PAYMENT: 'create_tds_payment',
  GST_MAKE_PAYMENT: 'create_gst_payment',
  OSV_UPLOAD: 'upload_osv',
  APPROVE_REQUISITION: 'approve_requisition',
  REJECT_REQUISITION: 'reject_requisition',
  FULFILL_REQUISITION: 'fulfill_requisition',
  CLOSE_REQUISITION: 'close_requisition',
  CREATE_SHIPMENT: 'create_shipment',
  PO_REJECT: 'reject_po',
  ADD_PO_ITEM: 'add_po_item',
  APPROVE_LISTING: 'approve_listing',
  REJECT_LISTING: 'reject_listing',
  ADD_EDIT_ITEM_LISTING: 'add_item_listing',
  PRICE_TREND_LISTING: 'price_trend_listing',
  ASSIGN_BUYER_LISTING: 'assign_buyer_listing',
  SELLER_PLACING_TRUCK: 'seller_placing_truck',
  ASSIGN_TRUCK: 'assign_truck',
  UPDATE_TRUCK_DETAILS: 'update_truck_details',
  UPDATE_PICKUP_DETAILS: 'update_pickup_details',
  UPDATE_TRANSPORTER_INVOICE: 'update_transporter_invoice',
  APPROVE_TRANSPORTER_INVOICE: 'approve_transporter_invoice',
  MOVE_TO_EPR: 'move_to_epr',
  MOVE_TO_QC_DONE: 'move_to_qc_done',
  QUALITY_PARAMETERS: 'quality_parameters',
  MARK_AS_DELIVERED: 'mark_as_delivered',
  MARK_AS_DISPATCH: 'mark_as_dispatch',
  ACCOUNT_VERIFICATION: 'account_verification',
  APPROVE_REJECT_SO: 'approve_so',
  ITEM_ADDITION_SO: 'item_addition',
  UPLOAD_AGGREGATOR_DOC: 'upload_aggregator_docs',
  MOVE_TO_MATERIAL_LOADED: 'move_to_material_loaded',
  FINAL_QTY_SO: 'final_quantity',
  PAYMENT_TERM_SO: 'payment_term',
  ADMIN_VERIFICATION_SO: 'admin_verification',
  MARK_ORDER_VERIFIED: 'mark_as_order_verified',
  PO_SAVE: 'save_po',
  FORCE_CLOSE_PO: 'force_close',
  CHANGE_TREND_LISTING: 'change_kam',
  CONFIRM_INVOICE: 'confirm_invoice',
  VIEW_LOGS: 'view_logs',
  QUALITY_PARAMETERS_LOGISTIC: 'quality_logistic_parameters',
  DEACTIVATE_CITIES: 'deactivate_cities',
  DELETE_LOGISTIC_ROUTE: 'delete_logistic_route',
  SALES_INVOICE_EWAY_UPLOAD: 'sales_invoice_ewaybill_upload',
  MARGIN_DIP_APPROVAL: 'margin_dip_approval',
  EDIT_OR_DELETE_PO_ITEM: 'edit_or_delete_po',
  RECYCLER_DOCUMENTS_PERMISSION: 'recycler_documents',
  UPLOAD_AGGREGATOR_PAYMENT: 'upload_aggregator_payment',
  ADD_RECEIVED_QTY: 'add_received_quantity',
  AGGREGATOR_MANUAL_PAYMENT: 'aggr_manual_payment',
  APPROVE_REJECT_BUYER_NOTE: 'approve_or_reject_buyer_note',
  EDIT_NOTE_TO_BUYER: 'edit_note_to_buyer',
  ADD_ITEM_NOTE_TO_BUYER: 'add_item_note_to_buyer',
  APPROVE_NOTE_TO_SELLER: 'approve_note_to_seller',
  EDIT_NOTE_TO_SELLER: 'edit_note_to_seller',
  DELETE_ITEM_LISTING: 'delete_item',
  INACTIVE_ACTIVE_REQUISITION: 'requisition_active_inactive',
  TRACK_NOW: 'track_now',
  CONVERT_TO_BUYER: 'convert_to_buyer',
  SPOC_CHANGE: 'spoc_change',
  CONVERT_TO_SELLER: 'conert_to_seller',
  POC_CHANGE: 'poc_change',
  LINKING_INVOICES: 'linking_invoices',
  ITEM_INFO_ACTIVE_INACTIVE: 'toggle_active_inactive_item_info',
  ITEM_INFO_RANKING_INFO: 'ranking_info',
  ORDER_REJECTED_SO: 'sale_order_rejection',
  UPDATE_PAYMENT_TERMS: 'update_payment_terms',
  DELETE_RECYCLERS: 'delete_recyclar',
  CHANGE_KAM_SO: 'change_kam_so',
  DELETE_TRANSPORTER: 'delete_transporter',
  LOGISTIC_COST_UPDATE: 'logistic_cost_update',
  SEND_TO_ZOHO: 'send_to_zoho',
  CREATE_CATEGORY: 'create_category',
  CREATE_MATERIAL: 'create_material',
  CREATE_ITEM: 'create_item',
  KYC_DOCUMENT: 'kyc_doc',
  APPROVE_REJECT_AGREEMENT: 'approve_reject_agreement',
  BUYER_DAILY_PRICES: 'buyer_daily_prices',
  SELLER_DAILY_PRICES: 'seller_daily_prices',
  DAILY_ADD_PRICES: 'add_prices',
  AGGREGATOR_PREFERRED_MATERIAL: 'agg_preferred_material',
  APPROVE_REJECT_AGGREGATOR_DOCUMENTS: 'agg_docs_action',
  CREATE_PV: 'create_pv',
  CREATE_SALE_INVOICE: 'create_sale_invoice',
  DELETE_AGGREGATOR_DOCS: 'delete_aggregator_docs',
  ENABLE_EINVOICE_TAG: 'enable_einvoice',
  AGGREGATOR_ADD_APP_LEADS: 'create_seller_app_leads',
  RECYCLER_ADD_APP_LEADS: 'create_buyer_app_leads',
  ITEM_CUSTOM_ATTRIBUTE: 'create_item_custom_attributes',
  INITIATE_AUCTION: 'initiate_auction',
  ADD_GSTR3B_DETAILS: 'add_gstr3B_details',
  EDIT_GSTR3B_DETAILS: 'edit_gstr3B_details',
  DELETE_GSTR3B_DETAILS: 'delete_gstr3B_details',
  MANAGE_ONBOARDING: 'manage_onboarding',
  SEGMENTATION_EDIT: 'can_edit',
  SEGMENTATION_CREATE: 'can_create',
  AGGREGATOR_ADD_ADDRESS: 'create_seller_address',
  INITIATE_VIDEO_KYC: 'initiate_video_kyc',
  SELLER_DOCS_UPLOAD: 'seller_docs_upload',
  COMPLETE_SHIPMENT: 'complete_shipment',

  // MISSING PERMISSIONS IN CODE LEVEL
  CREDIT_APPROVAL: 'credit_approval', // backend it is controlled
  AGGREGATOR_ACCOUNT_VERIFICATION: 'aggr_payment_account_verification', // duplicate permission
  ASSIGN_REASSIGN_SALEORDER: 'assign_reassign_saleorder',
  USER_AVAILABILITY: 'user_availabilty',
  COMPLETE_REGISTRATION: 'complete_seller_onboarding',
};
