import React from 'react';
import PropTypes from 'prop-types';
import { flatMap } from 'lodash';
import DropdownSelect from '../DropdownSelect';
import { AGGREGATOR_STATUS, OSV_STATUS } from '../../constants/Constants';

const OsvStatus = ({ onChange, value, ...props }) => {
  const options = [
    OSV_STATUS.NOT_INITIATED,
    OSV_STATUS.UNDER_REVIEW,
    OSV_STATUS.VERIFIED,
    OSV_STATUS.FAILED,
  ];
  return (
    <DropdownSelect
      getOptionLabel={(option) => {
        return option.name;
      }}
      formatOptionLabel={(option) => {
        const virtualStyle = {
          borderBottom: '1px solid',
        };
        return <div style={option.isVirtual ? virtualStyle : {}}>{option.name}</div>;
      }}
      onChange={onChange}
      getOptionValue={(option) => option.id}
      options={options}
      value={value}
      maxMenuHeight={150}
      {...props}
    />
  );
};

OsvStatus.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func,
};

export default OsvStatus;
