const Colors = {
  warning_100: '#FFFAEB',
  success_50: '#ECFDF3',
  bg_grey: '#F9F9F9',
  border_color: '#000000',
  base_white: '#FFFFFF',
  text_grey: '#475467',
  text_gray: '#1F2937',
  text_silver: '#98A2B3',
  bg_secondary: '#F9FAFB',
  border_primary: '#D0D5DD',
  text_secondary: '#344054',
  text_primary: '#101828',
  utility_gray: '#F2F4F7',
  tertiary_color: '#156751',
  brand_primary: '#1B745D',
  utility_blue: '#1570EF',
  border_secondary: '#EAECF0',
  display_label: '#667085',
  display_value: '#101828',
  border_green: '#73A79A',
  text_green: '#156751',
  button_green: '#1B745D',
  border_red: '#FDA29B',
  text_red: '#B42318',
  background_gray: '#F9FAFB',
  background_green: '#FAFEF5',
  background_grey: '#FCFCFD',
  border_gray: '#D0D5DD',
  border_green_light: '#AAF0C4',
  LISTING_STATUS_BORDER_COLORS: {
    DRAFT: '#9FBBB3',
    IN_REVIEW: '#B2DDFF',
    EXPIRED: '#e8b34a',
    REJECTED: '#e94340',
    CLOSED: '#9FBBB3',
  },
  text_quaternary: '#667085',
  fg_primary: '#1B745D0D',
  border_spacing: '#CECECE',
  blue: '#155EEF',
  button_secondary_color_border: '#73A79A',
  button_box_shadow: '#1018280D',
  error_color: '#F04438',
  map_stroke: '#1570EF',
};

export default { Colors };
