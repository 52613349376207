import React from 'react';
import PropTypes from 'prop-types';
import DropdownSelect from '../DropdownSelect';
import { useCatalog } from '../../../hooks';

const MaterialTypeDropdown = ({ typeOptions, value, allMaterialsRequired = false, ...props }) => {
  if (allMaterialsRequired) {
    const allMaterials = [{ id: '-1', type: 'MATERIAL', name: 'All Materials' }];
    typeOptions = allMaterials.concat(typeOptions);
  }
  return (
    <DropdownSelect
      options={typeOptions}
      getOptionLabel={(option) => option.name}
      getOptionValue={(option) => option.id}
      value={value}
      {...props}
    />
  );
};

MaterialTypeDropdown.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func,
  options: PropTypes.array,
};

export default MaterialTypeDropdown;
