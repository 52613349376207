import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  accountDetails: null,
  deviceInfo: null,
  roleNameByPermission: null,
};

const UserSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUserDetails: (state, action) => {
      state.accountDetails = action.payload;
    },
    setDeviceInfo: (state, action) => {
      state.deviceInfo = action.payload;
    },
    setRoleNamePermissions: (state, action) => {
      state.roleNameByPermission = action.payload;
    },
  },
});

export const userActions = UserSlice.actions;
export default UserSlice.reducer;
