import React from 'react';
import PropTypes from 'prop-types';
import { flatMap } from 'lodash';
import DropdownSelect from '../DropdownSelect';
import { AGGREGATOR_STATUS } from '../../constants/Constants';

const SellerStatusDropdown = ({ onChange, value, ...props }) => {
  const options = [
    AGGREGATOR_STATUS.NEW,
    AGGREGATOR_STATUS.ACQUIRED,
    AGGREGATOR_STATUS.ACQUIRED_BUT_POTENTIAL_CHURN,
    AGGREGATOR_STATUS.ACTIVE,
    AGGREGATOR_STATUS.CHURNED,
    AGGREGATOR_STATUS.POTENTIAL_CHURN,
    AGGREGATOR_STATUS.REACTIVATED,
    AGGREGATOR_STATUS.DEACTIVATED,
    AGGREGATOR_STATUS.ONBOARDED,
  ];
  return (
    <DropdownSelect
      getOptionLabel={(option) => {
        return option.name;
      }}
      formatOptionLabel={(option) => {
        const virtualStyle = {
          borderBottom: '1px solid',
        };
        return <div style={option.isVirtual ? virtualStyle : {}}>{option.name}</div>;
      }}
      onChange={onChange}
      getOptionValue={(option) => option.id}
      options={options}
      value={value}
      maxMenuHeight={150}
      {...props}
    />
  );
};

SellerStatusDropdown.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func,
};

export default SellerStatusDropdown;
