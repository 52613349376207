import { useSelector } from 'react-redux';
import { sortBy } from 'lodash';

export default function useAppConfig() {
  const { businessTypes } = useSelector(({ config }) => config);
  const recyclerAttributes = sortBy(
    businessTypes
      ?.find((type) => type.code === 'RECLR')
      ?.profileAttributes.filter((attr) => [109, 110, 111, 113, 114].indexOf(attr.id) > -1),
    'srNo'
  );
  return { recyclerAttributes };
}
