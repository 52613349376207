import { filter, flatMap, isEmpty, map } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { useAsyncEffect, useRole, useStakeholders } from '../../../hooks';
import DropdownSelect from '../DropdownSelect';

const ReportingListDropDown = ({ onChange, value, isEdit = {}, ...props }) => {
  const { kamUsers } = useStakeholders();
  const allUsersData = flatMap(kamUsers);

  const activeAllUsersData = filter(allUsersData, (option) => option?.activated);
  const removedCurrentUser = !isEmpty(isEdit)
    ? activeAllUsersData?.filter((obj) => {
        return obj?.id !== isEdit?.id;
      })
    : activeAllUsersData;

  const filteredUsersList = map(removedCurrentUser, (option) => {
    return {
      ...option,
      id: option?.userId,
    };
  });
  // const removedCurrentUser = !isEmpty(isEdit)
  //   ? filteredUsersList?.filter((obj) => {
  //       return obj?.id !== isEdit?.id;
  //     })
  //   : filteredUsersList;

  return (
    <DropdownSelect
      onChange={onChange}
      options={filteredUsersList || []}
      value={value}
      {...props}
      getOptionLabel={(option) => {
        return option?.firstName;
      }}
      getOptionValue={(option) => option?.userId}
    />
  );
};

ReportingListDropDown.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func,
};

export default ReportingListDropDown;
