import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  kamUsers: null,
  rsmUsers: null,
  spocUsers: null, // will only come for rsm login
};

const KamUserSlice = createSlice({
  name: 'kamUser',
  initialState,
  reducers: {
    setKamUserList: (state, action) => {
      state.kamUsers = action.payload;
    },
    setRsmUserList: (state, action) => {
      state.rsmUsers = action.payload;
    },
    setSpocList: (state, action) => {
      state.spocUsers = action.payload;
    },
  },
});
export const kamUserActions = KamUserSlice.actions;
export default KamUserSlice.reducer;
