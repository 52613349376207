import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  customerDetails: null,
  recyclerList: null,
  aggregatorList: null,
};

const CustomerSlice = createSlice({
  name: 'customer',
  initialState,
  reducers: {
    setCustomerDetails: (state, action) => {
      state.customerDetails = action.payload;
      state.recyclerList = action.payload;
    },
    setAggDetails: (state, action) => {
      state.aggregatorList = action.payload;
    },
    setRecyclerDetails: (state, action) => {
      state.recyclerListConstant = action.payload;
    },
  },
});

export const customerActions = CustomerSlice.actions;
export default CustomerSlice.reducer;
