import { createSlice } from '@reduxjs/toolkit';

const initialAuth = {
  token: null,
  refreshToken: null,
};

const AuthSlice = createSlice({
  name: 'auth',
  initialState: initialAuth,
  reducers: {
    setToken: (state, action) => {
      state.token = action.payload;
    },
    setRefreshToken: (state, action) => {
      state.refreshToken = action.payload;
    },
  },
});
export const authActions = AuthSlice.actions;
export default AuthSlice.reducer;
