/* eslint-disable consistent-return */
/* eslint-disable no-nested-ternary */
import { FaArrowLeft } from '@react-icons/all-files/fa/FaArrowLeft';
import classNames from 'classnames';
import * as _ from 'lodash';
import { flatMap } from 'lodash';
import React, { useEffect, useState } from 'react';
import {
  Badge,
  Button,
  Col,
  Modal,
  OverlayTrigger,
  Popover,
  Row,
  Image,
  Spinner,
} from 'react-bootstrap';
import { useHistory, Link } from 'react-router-dom';
import isUndefined from 'lodash/isUndefined';
import Theme from '../../theme/Theme';
import { useCatalog, useRegion } from '../../hooks';
import useResponsive from '../../hooks/useResponsive';
import {
  AGGREGATOR_DETAILS_URL,
  LISTING_ORDER_URL,
  LISTS_STATUS,
  ORDER_STATUS,
  PURCHASE_ORDER_URL,
  SALE_ORDERS_URL,
  SHIPMENTS_URL,
} from '../constants/Constants';
import {
  buildUrl,
  getDateDiff,
  toCapitalize,
  toLocalDate,
  toRupees,
  formatNumberToIndianSystem,
} from '../utils/Helper';

const { Colors } = Theme;

const OverlayCell = ({ id, overlay, hideOverlay, children, style, ...restProps }) => {
  if (hideOverlay) return children;
  return (
    <OverlayTrigger
      trigger={['hover', 'hover']}
      key="bottom"
      placement="top"
      rootClose
      overlay={
        <Popover id={id}>
          <Popover.Content style={style}>{overlay}</Popover.Content>
        </Popover>
      }
      {...restProps}>
      {children}
    </OverlayTrigger>
  );
};

const CheckboxCell = (props) => {
  const {
    getToggleSelectionProps,
    isCheckboxDisabled,
    isAllSelected,
    setAllSelected,
    ...restProps
  } = props;
  return (
    <div>
      <input {...getToggleSelectionProps()} type="checkbox" {...restProps} />
    </div>
  );
};

const Region = ({ cityId, stateId, className, hideOverlay = false }) => {
  const { cities, states } = useRegion();
  const city = cities ? cities[cityId] || states[stateId] : {};
  const overlay = <div>{[city?.name, city?.parent?.name].filter(Boolean).join(', ') || 'NA'}</div>;
  return (
    <OverlayCell hideOverlay={hideOverlay} overlay={overlay}>
      <span className={className}>
        {[city?.name, city?.parent?.code]?.filter(Boolean)?.join(', ') || 'NA'}
      </span>
    </OverlayCell>
  );
};

const Amount = (props) => {
  // if (props?.isSaleInfo && props?.value) {
  //   props.setLatestAmount(props.value);
  //   props.setIsSaleInfo(false);
  // }
  const { value = 0, prefix = '₹', className, postfix, ...rest } = props;
  if (Number.isNaN(value)) {
    return 0;
  }
  return (
    <>
      <span className={classNames('amount', className)} {...rest}>
        {toRupees(value, true)}
        {postfix}
      </span>
    </>
  );
};

const AmountConvertion = ({ value, flag = false }) => {
  if (value === 0) {
    return value;
  }
  const decimal = 2;
  const amount = flag ? value : value.toFixed(decimal);
  const formatedRupee = amount?.toString()?.replace(/\B(?=(?:(\d\d)+(\d)(?!\d))+(?!\d))/g, ',');
  return formatedRupee;
};

const Items = ({ value, isMobile }) => {
  const { items } = useCatalog();
  if (value === undefined || items === undefined) {
    return <div>NA</div>;
  }
  const itemArr = items !== null ? value?.map(({ itemId, id }) => items?.[itemId || id]) : [];

  return (
    <div>
      {!isUndefined(isMobile) ? (
        <span style={{ color: '#607581' }}>{itemArr?.[0]?.name}</span>
      ) : (
        itemArr?.[0]?.name || 'NA'
      )}
      {itemArr?.length > 1 && (
        <OverlayCell overlay={<div>{itemArr?.map((a) => a?.name)?.join(', ') || 'NA'}</div>}>
          <Badge className="rounded ml-1">+{itemArr?.length - 1}</Badge>
        </OverlayCell>
      )}
    </div>
  );
};

const BadgeOverLay = ({ values = [] }) => {
  if (values === null || values?.length === 0) return <div>NA</div>;
  return (
    <div>
      {values?.[0] || 'NA'}
      {values?.length > 1 && (
        <OverlayCell overlay={<div>{values?.map((a) => a)?.join(',') || 'NA'}</div>}>
          <Badge className="rounded">+{values?.length - 1}</Badge>
        </OverlayCell>
      )}
    </div>
  );
};

const itemUnitDisplay = (itemId, itemProp) => {
  let itemUnit = '';
  if (itemProp === undefined) {
    const { items } = useCatalog();
    itemUnit = flatMap(items)?.find((e) => e?.id === itemId);
  } else {
    itemUnit = flatMap(itemProp)?.find((e) => e?.id === itemId);
  }
  return toCapitalize(itemUnit?.unit) || 'Kg';
};

const PONUM = ({ value }) => {
  const { items } = useCatalog();
  const _items = flatMap(value?.items);
  const itemArr =
    items !== null
      ? _items?.map(({ itemId, price }) => {
          return {
            item: items[itemId],
            price,
          };
        })
      : [];
  return (
    <div>
      <OverlayCell
        placement="bottom-start"
        overlay={
          <>
            <span>Item Rate</span>
            <div>
              {itemArr
                .map((a, index) => {
                  return `${a?.item?.name}: ₹ ${toRupees(a?.price)}`;
                })
                ?.join(', ')}
            </div>
          </>
        }>
        <span className="text-left text-break">#{value?.poNumber}</span>
      </OverlayCell>
    </div>
  );
};

const PoDate = ({ value }) => {
  return (
    <div>
      <OverlayCell
        placement="bottom-start"
        overlay={
          <>
            <span>Expiry Date</span>
            <div>{toLocalDate(value?.expiryDate)}</div>
          </>
        }>
        <span className="pl-2">{toLocalDate(value?.createdDate)}</span>
      </OverlayCell>
    </div>
  );
};

const Aggregators = ({ AggregatorsArr }) => {
  return (
    <div>
      {AggregatorsArr.value[0]}{' '}
      {AggregatorsArr.value?.length > 1 && (
        <OverlayCell
          overlay={
            <div>
              {AggregatorsArr.value?.map((aggrigatorName, j) => (
                <Badge key={j} variant="primary" className="mr-2">
                  <OverlayCell hideOverlay={false}>
                    <span>{aggrigatorName || 'NA'}</span>
                  </OverlayCell>
                </Badge>
              ))}
            </div>
          }>
          <Badge className="rounded">+{AggregatorsArr.value?.length - 1}</Badge>
        </OverlayCell>
      )}
    </div>
  );
};

const Cities = ({ value }) => {
  const { cities } = useRegion();
  const cityArr = cities !== null ? value?.map((cityId) => cities[cityId]) : [];
  // eslint-disable-next-line no-debugger
  return (
    <div>
      {cityArr[0]?.name}{' '}
      {cityArr?.length > 1 && (
        <OverlayCell
          overlay={
            <div>
              {cityArr?.map((a, j) => (
                <Badge key={j} variant="primary" className="mr-2">
                  <Region cityId={a?.id} />{' '}
                </Badge>
              ))}
            </div>
          }>
          <Badge className="rounded">+{cityArr?.length - 1}</Badge>
        </OverlayCell>
      )}
    </div>
  );
};

const StatePrices = ({ value, className }) => {
  return (
    <div>
      <OverlayCell
        placement="bottom-start"
        overlay={
          <div style={{ width: '180px' }}>
            <h6 className="mb-2">Local Rates</h6>
            {value.map((res, idx) => (
              <div>
                <Row>
                  <Col md="7" className="float-left">
                    {res?.states?.map((a, j) => (
                      <span key={j} variant="primary" className="mr-2">
                        <Region stateId={a?.id} />
                        {j < res?.states?.length - 1 ? ', ' : ' '}
                        <br />
                      </span>
                    ))}
                  </Col>
                  <Col md="5" className="float-right">
                    <Amount style={{ color: '#1792c7' }} value={Number(res?.price)} />
                  </Col>
                </Row>
                {idx < value.length - 1 && <hr className={className} />}
              </div>
            ))}
          </div>
        }>
        <Badge className="rounded">{value?.length}</Badge>
      </OverlayCell>
    </div>
  );
};

const TotalPoQuantity = ({ value, className, status }) => {
  const itemList = flatMap(value);
  let balancedQty = 0;
  let totalQty = 0;
  _.forEach(itemList, (r) => {
    balancedQty += r.balanceQty;
    totalQty += r.qty;
  });
  return (
    <div className={className}>
      <OverlayCell
        placement="bottom-start"
        overlay={
          <div>
            <h6 className="mb-2">Total Quantity</h6>
            <span>{formatNumberToIndianSystem(totalQty || 0, 0)}</span>
          </div>
        }>
        <span>
          {status === 'IN_REVIEW'
            ? formatNumberToIndianSystem(totalQty, 0)
            : formatNumberToIndianSystem(balancedQty || 0, 0)}
        </span>
      </OverlayCell>
    </div>
  );
};

const States = ({ value, displayCount = 2 }) => {
  const { states } = useRegion();
  const stateArr = states !== null ? value?.map((stateId) => states[stateId]) : [];
  const staticStates = stateArr?.slice(0, displayCount);
  const overlayStates = stateArr?.slice(displayCount, stateArr.length);
  return (
    <div>
      {staticStates?.map((a, j) => a?.name).join(', ')}{' '}
      {stateArr?.length > displayCount && (
        <OverlayCell overlay={<div>{overlayStates?.map((a, j) => a?.name).join(', ')}</div>}>
          <Badge className="rounded">+{overlayStates?.length}</Badge>
        </OverlayCell>
      )}
    </div>
  );
};

const SaleOrders = ({ value, ...props }) => {
  return (
    <div {...props}>
      {value?.[0]?.id}
      {value?.length > 1 && (
        <OverlayCell overlay={<div>{value?.map((a) => a?.id)?.join(', ')}</div>}>
          <Badge className="rounded">+{value?.length - 1}</Badge>
        </OverlayCell>
      )}
    </div>
  );
};

const OrderStatus = (props) => {
  const {
    status = {},
    className,
    statusLogs = [],
    style = {},
    isAssessmentInProgress,
    isAssessmentDone,
    hideText,
    onClick,
    comments,
  } = {
    ...props,
  };
  const [statusLog, setStatusLog] = useState();
  const getPlacedTxt = () => {
    let txt = '';
    if (status?.id === ORDER_STATUS?.PLACED) {
      txt = 'Pending Assessment from KAM';
      if (isAssessmentInProgress) txt = 'KAM Assessment in Progress';
      if (isAssessmentDone) txt = 'Assessment Completed';
    }
    return txt;
  };

  const getCancelledTxt = () => {
    let txt = '';
    if (status?.id === ORDER_STATUS?.CANCELLED) {
      txt = statusLogs?.[statusLogs?.length - 1]?.reason;
    }
    return txt;
  };
  useEffect(() => {
    const log = statusLogs?.find((s) => s?.toStatus === status?.id);
    setStatusLog(log);
  }, [status]);
  const dateDiff = getDateDiff(statusLog?.changedDate, 'hours');
  return (
    <div className={className}>
      <Button
        title={
          hideText &&
          buildUrl(status.info, {
            value: dateDiff ? `${dateDiff}hrs` : 'few minutes',
            reason: statusLog?.reason,
            placedtxt: getPlacedTxt(),
          })
        }
        onClick={() => (typeof onClick === 'function' ? onClick() : null)}
        style={{
          cursor: onClick ? 'pointer' : 'auto',
          letterSpacing: '0.26px',
          width: '140px',
          border: 0,
          padding: '2px 0px',
          backgroundColor: status.colors,
          color: status.textColor,
          fontFamily: 'font-bold',
          ...style,
        }}>
        {status?.name}
      </Button>
      {!hideText && (
        <p className="m-0 text-small font-semibold">
          {buildUrl(status?.info, {
            value: dateDiff ? `${dateDiff}hrs` : 'few minutes',
            reason: getCancelledTxt(),
            placedtxt: getPlacedTxt(),
          })}{' '}
        </p>
      )}
    </div>
  );
};

const KamListingStatus = (props) => {
  const { status = {}, className, statusLogs = [], hideText, comments, isMobile } = props;
  const [statusLog, setStatusLog] = useState();

  useEffect(() => {
    const log = statusLogs?.find((s) => s.toStatus === status.id) || {};
    setStatusLog(log);
  }, [status]);

  const getCancelledTxt = () => {
    let txt = '';
    if (status.id === LISTS_STATUS.REJECTED) {
      txt = `| ${statusLogs[statusLogs?.length - 1]?.reason}`;
    }
    return txt;
  };

  return (
    <div className={`${className} d-flex align-items-center`}>
      <Badge
        title={getCancelledTxt()}
        style={{
          cursor: 'auto',
          letterSpacing: '0.26px',
          lineHeight: '20px',
          width: '100px',
          padding: '2px 0px',
          backgroundColor: status.colors,
          color: status.textColor,
          fontFamily: 'font-bold',
          borderRadius: '50px',
          border: `1px solid ${Colors.LISTING_STATUS_BORDER_COLORS[status?.id]}`,
          marginRight: '10px',
        }}>
        {status.name}
      </Badge>
      {!hideText && isUndefined(isMobile) && (
        <div className="mt-1">
          {/* <span className="text-small text-muted">
            {toLocalDate(statusLog?.changedDate, 'DD-MMM-YY')}{' '}
            {toLocalDate(statusLog?.changedDate, 'hh:mm a')}{' '}
          </span> */}
          <span className="m-0 text-small font-semibold">{getCancelledTxt()}</span>
        </div>
      )}
    </div>
  );
};

const KamRequisitionAndQuoteStatus = (props) => {
  const { status = {}, className, isMobile } = props;
  return (
    <>
      {!isMobile ? (
        <span className={className}>
          <Badge
            pill
            style={{
              cursor: 'auto',
              letterSpacing: '0.26px',
              minWidth: '120px',
              border: 0,
              padding: '8px 8px',
              backgroundColor: status.colors,
              color: status.textColor,
              fontFamily: 'font-bold',
              fontSize: '1em',
            }}>
            {status.name}
          </Badge>
        </span>
      ) : (
        <div className={className}>
          <Button
            style={{
              cursor: 'auto',
              letterSpacing: '0.26px',
              width: '100px',
              border: 0,
              padding: '2px 0px',
              backgroundColor: status.colors,
              color: status.textColor,
              fontFamily: 'font-bold',
            }}>
            {status.name}
          </Button>
        </div>
      )}
    </>
  );
};

const DisplayItemName = ({ id }) => {
  const { items } = useCatalog();
  const itemName = items?.[id]?.name;

  return <div>{itemName || 'NA'}</div>;
};

const Address = ({ value, short = false, className, style, shortAddr = false }) => {
  const address = [
    value?.flatBuilding,
    value?.landmark,
    value?.streetAddress,
    value?.city,
    value?.state,
    value?.zipCode,
  ]
    .filter(Boolean)
    .join(', ');

  // address = startCase(capitalize(address));

  return short ? (
    <OverlayCell hideOverlay={!address} placement="bottom" overlay={<div>{address}</div>}>
      <div>
        <Region className={className} hideOverlay cityId={value?.regionId || value?.region?.id} />
      </div>
    </OverlayCell>
  ) : shortAddr ? (
    address?.length > 126 ? (
      <OverlayCell hideOverlay={!address} placement="right" overlay={<div>{address}</div>}>
        <div>
          <span style={{ style }}>{`${address?.substring(0, 126)} ...` || '-'}</span>
        </div>
      </OverlayCell>
    ) : (
      <div>
        <span style={{ style }}>{address || 'NA'}</span>
      </div>
    )
  ) : (
    <div className={className}>
      <span style={{ style }}>{address || 'NA'}</span>
    </div>
  );
};

const BackButton = ({ className }) => {
  const history = useHistory();
  return (
    <Button
      className={classNames('p-0', className)}
      style={{ width: '45px' }}
      size="sm"
      variant="link"
      onClick={() => history.goBack()}>
      <FaArrowLeft /> Back
    </Button>
  );
};

const DoShow = ({ show, children }) => (show ? children : null);
const HasMobile = ({ children }) => {
  const { isMobile } = useResponsive();
  return isMobile ? children : null;
};
const HasDesktop = ({ children }) => {
  const { isMobile } = useResponsive();
  return !isMobile ? children : null;
};

const TreeHierarchy = ({ style = {} }) => {
  // Default Styling ... can be overriden through style prop
  const mystyle = {
    height: '41px',
    width: '60px',
    background: 'white',
    border: '3px solid grey',
    borderTop: '0px',
    borderRight: '0px',
    marginTop: '-25px',
    marginLeft: '20px',
    position: 'relative',
    zIndex: '-1',
    ...style,
  };

  return <div className="square" style={mystyle} />;
};

const CampaignSellerStatus = (props) => {
  const { status = {}, className } = props;

  return (
    <div className={className}>
      <Button
        style={{
          cursor: 'auto',
          letterSpacing: '0.26px',
          width: '140px',
          border: 0,
          padding: '2px 0px',
          backgroundColor: status.colors,
          color: status.textColor,
          fontFamily: 'font-bold',
        }}>
        {status.name}
      </Button>
    </div>
  );
};

const BadgeCellPill = ({ textColor, backgroundColorPill, children }) => (
  <Badge
    pill
    style={{
      cursor: 'auto',
      letterSpacing: '0.26px',
      minWidth: '90px',
      border: 0,
      padding: '6px',
      backgroundColor: backgroundColorPill,
      color: textColor,
      fontFamily: 'font-bold',
    }}>
    {children || 'NA'}
  </Badge>
);

const getBuyerPrice = (poItemId, poItems) => {
  return poItems?.find((i) => {
    return i?.id === poItemId;
  })?.price;
};
/**
 *
 * @param {*} show: state to show the popup
 * @param  setShow : function to update the state of popup
 * @param  title : title of popup
 * @param  handleChange : to perform action on clicking okay btn
 * @param  children
 * @returns Component
 */
const Popup = ({ show, setShow, title, handleChange, children }) => {
  return (
    <Modal show={show} size="sm" aria-labelledby="contained-modal-title-vcenter" centered>
      <Modal.Header>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>{children}</Modal.Body>
      <Modal.Footer>
        {handleChange && (
          <Button variant="primary" onClick={handleChange}>
            Okay
          </Button>
        )}
        <Button variant="secondary" onClick={() => setShow(false)}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default Popup;

const LinkNavigate = React.memo(
  ({ ID, flag, hideNavigation, className, children, emptyMessage, styles, ...restprops }) => {
    if (ID === undefined || ID == null) {
      return <div>{emptyMessage || 'NA'}</div>;
    }

    let URL = null;
    if (flag === 'PO') {
      URL = PURCHASE_ORDER_URL(ID);
    } else if (flag === 'SO') {
      URL = `${SHIPMENTS_URL}${ID}`;
    } else if (flag === 'REQ') {
      URL = `${SALE_ORDERS_URL}${ID}`;
    } else if (flag === 'AGG') {
      URL = `/${ID}${AGGREGATOR_DETAILS_URL}`;
    } else {
      URL = `${LISTING_ORDER_URL}${ID}`;
    }

    return (
      <Link
        style={
          styles
            ? { ...styles, ...(hideNavigation && { pointerEvents: 'none' }) }
            : {
                ...styles,
                textDecoration: 'underline',
                color: '#0645ad',
                background: 'white',
                ...(hideNavigation && { pointerEvents: 'none' }),
              }
        }
        to={URL}
        className={className}
        {...restprops}>
        {children || ID}
      </Link>
    );
  }
);

const PrimaryOutlineButton = ({
  label,
  size,
  className,
  src,
  textStyle,
  onClick,
  disabled,
  loading,
  buttonStyle,
  imageStyle,
  buttonLoading,
  buttonClassName,
  imageSrc, // for images wrapped in ReactComponent
}) => {
  return (
    <Button
      size={size}
      style={{
        backgroundColor: '#EDFCFE',
        borderColor: '#EDFCFE',
        color: '#34948E',
        ...buttonStyle,
      }}
      className={buttonClassName}
      disabled={disabled || buttonLoading}
      loading={loading || buttonLoading}
      onClick={onClick}>
      {src ? <Image src={src} style={imageStyle} /> : <>{imageSrc}</>}
      <span className={className} style={textStyle}>
        {label}
        {buttonLoading && <Spinner size="sm" animation="border" />}
      </span>
    </Button>
  );
};
const SecondaryOutlineButton = ({
  label,
  size,
  className,
  src,
  textStyle,
  onClick,
  disabled,
  loading,
  buttonStyle,
  imageStyle,
  buttonLoading,
  buttonClassName,
}) => {
  return (
    <Button
      size={size}
      style={{
        ...buttonStyle,
        backgroundColor: '#FFFFFF',
        borderColor: '#FDA29B',
        color: '#B42318',
      }}
      className={buttonClassName}
      disabled={disabled || buttonLoading}
      loading={loading || buttonLoading}
      onClick={onClick}>
      <Image src={src} style={imageStyle} />
      <span className={className} style={textStyle}>
        {label}
        {buttonLoading && <Spinner size="sm" animation="border" />}
      </span>
    </Button>
  );
};

const PrimaryButton = ({
  label,
  size,
  className,
  src,
  textStyle,
  onClick,
  disabled,
  loading,
  buttonStyle,
  imageStyle,
  buttonLoading,
  type,
  buttonClassName,
  imageSrc,
}) => {
  return (
    <Button
      size={size}
      style={{
        cursor: 'pointer',
        backgroundColor: '#34948E',
        borderColor: '#34948E',
        color: '#FFFFFF',
        ...buttonStyle,
      }}
      type={type}
      // className={buttonClassName}
      className={classNames('', buttonClassName)}
      disabled={disabled || buttonLoading}
      // loading={loading || buttonLoading}
      onClick={onClick}>
      {src ? <Image src={src} style={imageStyle} /> : <>{imageSrc}</>}
      <span className={className} style={textStyle}>
        {label}
        {buttonLoading && <Spinner size="sm" animation="border" />}
      </span>
    </Button>
  );
};

const SecondaryButton = ({
  label,
  size,
  className,
  src,
  textStyle,
  onClick,
  disabled,
  loading,
  buttonStyle,
  imageStyle,
  buttonClassName,
  textClassName,
}) => {
  return (
    <Button
      size={size}
      className={buttonClassName}
      style={{
        backgroundColor: '#FFFFFF',
        borderColor: '#D0D5DD',
        color: '#344054',
        ...buttonStyle,
      }}
      disabled={disabled}
      loading={loading}
      onClick={onClick}>
      <Image src={src} style={imageStyle} />
      <span className={textClassName} style={{ verticalAlign: 'middle', ...textStyle }}>
        {label}
      </span>
    </Button>
  );
};

const ButtonVariant = ({
  label,
  size,
  className,
  src,
  textStyle,
  onClick,
  disabled,
  buttonStyle,
  imageStyle,
  imageSrc,
}) => {
  return (
    <Button
      size={size}
      style={{
        ...buttonStyle,
        backgroundColor: 'white',
        borderColor: 'white',
      }}
      disabled={disabled}
      onClick={onClick}>
      {src ? <Image src={src} style={imageStyle} /> : <>{imageSrc}</>}
      <span className={className} style={textStyle}>
        {label}
      </span>
    </Button>
  );
};

const PrimaryDangerButton = ({
  label,
  size,
  className,
  src,
  textStyle,
  onClick,
  disabled,
  loading,
  buttonStyle,
  imageStyle,
  buttonLoading,
  buttonClassName,
}) => {
  return (
    <Button
      size={size}
      style={{
        ...buttonStyle,
        backgroundColor: '#D92D20',
        borderColor: '#D92D20',
        color: '#FFFFFF',
      }}
      className={buttonClassName}
      disabled={disabled || buttonLoading}
      loading={loading}
      onClick={onClick}>
      <Image src={src} style={imageStyle} />
      <span className={className} style={textStyle}>
        {label}
      </span>
      {buttonLoading && <Spinner size="sm" animation="border" />}
    </Button>
  );
};

const SecondaryDangerButton = ({
  label,
  size,
  className,
  src,
  textStyle,
  onClick,
  disabled,
  loading,
  buttonStyle,
  imageStyle,
  buttonLoading,
}) => {
  return (
    <Button
      size={size}
      style={{
        ...buttonStyle,
        backgroundColor: '#FEF3F2',
        borderColor: '#FEF3F2',
        color: '#B42318',
      }}
      className={buttonLoading && 'ButtonLoading'}
      disabled={disabled || buttonLoading}
      loading={loading}
      onClick={onClick}>
      <Image src={src} style={imageStyle} />
      <span className={className} style={textStyle}>
        {label}
      </span>
      {buttonLoading && <Spinner size="sm" animation="border" />}
    </Button>
  );
};

const UploadButton = ({
  label,
  size,
  buttonClassName,
  imageClassName,
  textClassName,
  src,
  textStyle,
  onClick,
  disabled,
  loading,
  buttonStyle,
  imageStyle,
  buttonLoading,
}) => {
  return (
    <Button
      size={size}
      style={{
        ...buttonStyle,
        backgroundColor: '#FFFFFF',
        borderColor: '#34948E',
        color: '#34948E',
      }}
      className={buttonLoading && 'ButtonLoading'}
      disabled={disabled || buttonLoading}
      loading={loading}
      onClick={onClick}>
      <Image src={src} style={imageStyle} className={imageClassName} />
      <span className={textClassName} style={textStyle}>
        {label}
        {buttonLoading && <Spinner size="sm" animation="border" />}
      </span>
    </Button>
  );
};

const PurchaseOrderStatus = (props) => {
  const { status = {}, className, isMobile } = props;
  return (
    <div className={className}>
      <Button
        style={{
          cursor: 'auto',
          letterSpacing: '0.26px',
          width: '100px',
          border: 0,
          padding: '2px 0px',
          backgroundColor: status.colors,
          color: status.textColor,
          fontFamily: 'font-bold',
        }}>
        {status?.name}
      </Button>
    </div>
  );
};

const CreditNotes = ({ value }) => {
  if (value) {
    const list = value?.split(',');

    return (
      <div>
        <span>{list?.[0]}</span>
        {list?.length > 1 && (
          <OverlayCell overlay={<div>{list?.join(`, `)}</div>}>
            <Badge className="rounded ml-1">+{list?.length - 1}</Badge>
          </OverlayCell>
        )}
      </div>
    );
  }
  return '-';
};

const InvoiceNumbers = ({ invoiceNumber, debitNotes }) => {
  // Check if invoiceNumber exists and append to value
  let value = '';
  invoiceNumber && (value += `${invoiceNumber}`);
  // Check if debitNotes exists and append to value with a comma
  if (debitNotes) {
    // If value already has content, add a comma before appending debitNotes
    value ? (value += `,${debitNotes}`) : (value += `${debitNotes}`);
  }

  if (value) {
    const list = value?.split(',');

    return (
      <div>
        <span>{list?.[0]}</span>
        {list?.length > 1 && (
          <OverlayCell overlay={<div>{list.join(`, `)}</div>}>
            <Badge className="rounded ml-1">+{list?.length - 1}</Badge>
          </OverlayCell>
        )}
      </div>
    );
  }
  return '-';
};

const PrimaryVariantButton = ({
  label,
  size,
  className,
  src,
  textStyle,
  onClick,
  disabled,
  loading,
  buttonStyle,
  imageStyle,
  buttonLoading,
  buttonClassName,
  textClassName,
}) => {
  return (
    <Button
      size={size}
      style={{
        ...buttonStyle,
        backgroundColor: '#FFFFFF',
        borderColor: '#73A79A',
        color: '#156751',
      }}
      className={buttonClassName}
      disabled={disabled || buttonLoading}
      loading={loading || buttonLoading}
      onClick={onClick}>
      <Image src={src} style={imageStyle} />
      <span className={textClassName} style={textStyle}>
        {label}
        {buttonLoading && <Spinner size="sm" animation="border" />}
      </span>
    </Button>
  );
};

export const ResellItems = ({ value, isMobile }) => {
  const { items } = useCatalog();

  if (value === undefined || items === undefined) {
    return <div>NA</div>;
  }
  const itemArr =
    items !== null ? flatMap(value)?.map(({ itemId, id }) => items?.[itemId || id]) : [];

  return (
    <div>
      {!isUndefined(isMobile) ? (
        <span style={{ color: '#607581' }}>{itemArr?.[0]?.name}</span>
      ) : (
        itemArr?.[0]?.name || 'NA'
      )}
      {itemArr?.length > 1 && (
        <OverlayCell overlay={<div>{itemArr?.map((a) => a?.name)?.join(', ') || 'NA'}</div>}>
          <Badge className="rounded ml-1">+{itemArr?.length - 1}</Badge>
        </OverlayCell>
      )}
    </div>
  );
};

export const getFinancialYearRange = () => {
  const currentDate = new Date();
  const currentYear = currentDate.getFullYear();
  const startOfYear = new Date(`${currentYear}-04-01T00:00:00.000Z`);
  const isAfterApril = currentDate >= startOfYear;
  const startYear = isAfterApril ? currentYear : currentYear - 1;
  const endYear = startYear + 1;

  const startDate = `${startYear}-04-01T00:00:00.000Z`;
  const endDate = `${endYear}-03-31T23:59:59.999Z`;

  return [startDate, endDate];
};

export {
  CheckboxCell,
  Region,
  OverlayCell,
  Amount,
  PONUM,
  Items,
  Address,
  DisplayItemName,
  OrderStatus,
  BackButton,
  DoShow,
  PoDate,
  Cities,
  HasMobile,
  HasDesktop,
  States,
  StatePrices,
  TotalPoQuantity,
  KamListingStatus,
  KamRequisitionAndQuoteStatus,
  Aggregators,
  TreeHierarchy,
  SaleOrders,
  itemUnitDisplay,
  AmountConvertion,
  CampaignSellerStatus,
  BadgeCellPill,
  getBuyerPrice,
  Popup,
  BadgeOverLay,
  LinkNavigate,
  PrimaryButton,
  PrimaryOutlineButton,
  SecondaryOutlineButton,
  SecondaryButton,
  PrimaryDangerButton,
  PurchaseOrderStatus,
  ButtonVariant,
  SecondaryDangerButton,
  UploadButton,
  CreditNotes,
  InvoiceNumbers,
  PrimaryVariantButton,
};
