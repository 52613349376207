import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  incorrectDocList: null,
};

const DocumentSlice = createSlice({
  name: 'document',
  initialState,
  reducers: {
    setIncorrectDocs: (state, action) => {
      state.incorrectDocList = action.payload;
    },
  },
});
export const documentActions = DocumentSlice.actions;
export default DocumentSlice.reducer;
