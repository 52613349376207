import { useFormik } from 'formik';
import { Button, Col, Form, FormControl, InputGroup, Modal } from 'react-bootstrap';
import React from 'react';
import './UserProfileModal.scss';
import { toast } from 'react-toastify';
import { useAccount, useAccountPassword, useInitApp } from '../../hooks/hooks';
import { displayToastMessage, hasError } from '../../shared/utils/Helper';
import { useStakeholders } from '../../hooks';
import { TOAST_TYPES } from '../../shared/constants/Constants';
import { ErrorCode } from '../../shared/constants';

const UserProfileModal = (props) => {
  const { show, setShow } = { ...props };
  const [
    { updateError, updating, updateValue, getError, fetching, getValue },
    doUpdateAccount,
    doGetAccount,
  ] = useAccountPassword();
  const { currentAccount } = useStakeholders();
  const { updateProfile } = useAccount();

  const formik = useFormik({
    initialValues: {
      firstName: currentAccount?.firstName || '',
      mobile: currentAccount?.mobile || '',
      email: currentAccount?.email || '',
    },
    enableReinitialize: true,
    validateOnBlur: true,
    validateOnChange: true,
    onSubmit: async (values) => {
      const res = await doUpdateAccount(values);
      const error = hasError(res);
      if (error) {
        displayToastMessage(TOAST_TYPES?.ERROR, error?.message, false);
      } else {
        updateProfile();
        displayToastMessage(TOAST_TYPES?.SUCCESS, ErrorCode?.UPDATE_ACCOUNT_DETAILS);
      }
      closeModal();
    },
  });

  const closeModal = () => {
    setShow(false);
  };

  return (
    <Modal
      show={show}
      onHide={closeModal}
      onExit={() => formik.resetForm()}
      backdrop="static"
      size="md"
      centered>
      <Modal.Header closeButton onHide={closeModal}>
        <Modal.Title>Profile</Modal.Title>
      </Modal.Header>
      <Modal.Body className="p-4">
        <Form noValidate>
          <Form.Row>
            <Form.Group as={Col} md="12">
              <Form.Label>First Name</Form.Label>
              <InputGroup>
                <FormControl
                  type="text"
                  placeholder="Enter First Name"
                  {...formik.getFieldProps('firstName')}
                  isInvalid={formik.touched.firstName && !!formik.errors.firstName}
                />
                <Form.Control.Feedback type="invalid">
                  {formik.errors.firstName}
                </Form.Control.Feedback>
              </InputGroup>
            </Form.Group>
            <Form.Group as={Col} md="12">
              <Form.Label>Mobile Number</Form.Label>
              <InputGroup>
                <FormControl
                  type="text"
                  placeholder="Enter Mobile Number"
                  {...formik.getFieldProps('mobile')}
                  isInvalid={formik.touched.mobile && !!formik.errors.mobile}
                />
                <Form.Control.Feedback type="invalid">{formik.errors.mobile}</Form.Control.Feedback>
              </InputGroup>
            </Form.Group>
            <Form.Group as={Col} md="12">
              <Form.Label>Email</Form.Label>
              <FormControl
                type="text"
                placeholder="Enter Email"
                disabled
                {...formik.getFieldProps('email')}
                isInvalid={formik.touched.email && !!formik.errors.email}
              />
              <Form.Control.Feedback type="invalid">
                {formik.errors.firstName}
              </Form.Control.Feedback>
            </Form.Group>
          </Form.Row>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" className="pr-5 pl-5 mr-3 cancel-button" onClick={closeModal}>
          Cancel
        </Button>
        <Button
          type="submit"
          disabled={!(formik?.dirty && formik?.isValid)}
          onClick={formik.handleSubmit}
          style={{ padding: '5px 50px 5px 50px' }}>
          Save
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
export default React.memo(UserProfileModal);
