import { filter, find, groupBy, map } from 'lodash';
import useRole from './useRole';
import useRoleByPermission from './useRoleByPermission';

export default function useGroupByModulesPermissions() {
  const { groupsData, modulesData } = useRole();

  // creating map object for nav
  const { allPermissionsByRoleName } = useRoleByPermission();

  // filtering the only selected permissions
  const filteredPermissionByRoleName = filter(
    allPermissionsByRoleName,
    (option) => option?.hasAccess
    // || option?.morePermission
  );

  // linking permissions to modules
  const groupedModuleIds = groupBy(filteredPermissionByRoleName, 'moduleId');

  const mappedModulesByPermissions = map(groupedModuleIds, (moduleOption, key) => {
    const filteredModules = find(modulesData, (option) => {
      return option?.id === parseInt(key);
    });
    return {
      ...filteredModules,
      permissions: moduleOption,
    };
  });

  // linking  modules to groups
  const groupedGroupIds = groupBy(mappedModulesByPermissions, 'groupId');
  const groupList = [];
  map(groupedGroupIds, (groupOption, key) => {
    filter(groupsData, (option) => {
      if (option?.id === parseInt(key)) {
        const groupObject = {
          id: option?.id,
          name: option?.name,
          nameFormatted: option?.nameFormatted,
          appCode: option?.appCode,
          modules: groupOption,
        };
        groupList?.push(groupObject);
      }
      return groupList;
    });
  });

  return [{ groupList }];
}
