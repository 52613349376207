export const ALL_MODULES = {
  SHIPMENT: 'shipment',
  PURCHASE_ORDER: 'purchase_order',
  ITEM_MASTER: 'items_master',
  LOGISTIC_ITEMS: 'logistic_items',
  SELLERS: 'sellers',
  BUYERS: 'buyers',
  TRANSPORTERS: 'transporters',
  SALE_ORDER: 'sale_order',
  LISTINGS: 'listings',
  AGGREGATOR_PAYMENTS: 'aggregator_payments',
  TRANSPORTER_PAYMENTS: 'transporter_payments',
  GST_PAYMENTS: 'gst_payments',
  RECEIVABLES: 'receivables',
  GST_FILINGS: 'gst_filings',
  GST_RECONCILIATION: 'gst_econciliation',
  PRICE_LIST: 'price_list',
  DAILY_PRICE: 'daily_prices',
  DASHBOARD: 'dashboard',
  ACTIVE_CITIES: 'active_cities',
  RM_QRF: 'rm_qrf',
  AGGREGATOR_PAYMENT_TERMS: 'seller_payment_terms',
  RECYCLER_PAYMENT_TERMS: 'buyer_payment_terms',
  CUSTOMER_VENDOR_MAPPING: 'customer_vendor_mapping',
  CREDIT_LIMIT: 'credit_limit',
  LOGS: 'logs',
  PRE_DISPATCH_TRACKING: 'pre_dispatch_tracking',
  ROUTES: 'routes',
  LOGISTIC_MANAGER: 'logistics_manager',
  TRACKING_DASHBOARD: 'tracking_dashboard',
  KAM: 'kam',
  USERS: 'users',
  USER_MAPPING: 'customer_vendor_mapping',
  SEGMENTATION: 'segmentation',
  ORDER_ALLOCATION: 'order_allocation',
  MANAGE_UPDATES_MODULE: 'manage_updates',
};
