import React from 'react';
import PropTypes from 'prop-types';
import * as _ from 'lodash';
import { filter, includes } from 'lodash';
import DropdownSelect from '../DropdownSelect';
import { useCatalog, useStakeholders } from '../../../hooks';

const allCategoriesArr = { id: 'All', name: 'All Categories' };

const CategoryDropdown = ({ MaterialTypeId, aLLCategoriesRequired = false, ...props }) => {
  const { itemsFlat, categoriesFlat } = useCatalog();
  const { currentAccount } = useStakeholders();

  const splitCategory = currentAccount?.category?.split(',');

  let filterCategories =
    MaterialTypeId !== undefined
      ? itemsFlat
          ?.filter((item) => item?.categoryDTO?.materialType?.id === MaterialTypeId)
          .map((item) => item.categoryDTO)
      : categoriesFlat;

  filterCategories = !includes(splitCategory, 'All')
    ? itemsFlat
        .filter((option) => splitCategory.includes(option?.categoryDTO?.materialType?.name))
        .map((option) => option?.categoryDTO)
    : categoriesFlat;

  filterCategories = _.uniqBy(filterCategories, (e) => e.id);

  if (aLLCategoriesRequired) {
    filterCategories.unshift(allCategoriesArr);
  }

  return <DropdownSelect options={filterCategories} {...props} />;
};

CategoryDropdown.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func,
};

export default CategoryDropdown;
