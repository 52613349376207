import { isEmpty } from 'lodash';

export function getSortBy(sort = []) {
  if (isEmpty(sort)) return undefined;
  const sortBy = sort[0] || {};
  const orderBy = sortBy.desc ? 'desc' : 'asc';
  return `${sortBy.id},${orderBy}`;
}

export function getSortForDynamicColumns(sort = [], columns) {
  if (isEmpty(sort)) return undefined;
  const columnsSortName = columns?.filter((i) => i?.id === sort?.[0]?.id);
  const sortBy = sort[0] || {};
  const orderBy = sortBy.desc ? 'desc' : 'asc';
  return `${columnsSortName?.[0]?.entityMeta?.columnName},${orderBy}`;
}

export function getRecyclerIdsByName(recyclers = {}, query = '') {
  const recyclerIds = Object.values(recyclers)
    ?.filter((r) => r.businessName?.toLowerCase().includes(query?.toLowerCase()))
    ?.map((a) => a.id);
  return recyclerIds;
}

export function getTransporterIdsByName(transporters = {}, query = '') {
  const transporterIds = Object.values(transporters)
    ?.filter((r) => r.name?.toLowerCase().includes(query?.toLowerCase()))
    ?.map((a) => a.id);
  return transporterIds;
}
