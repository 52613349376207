import React, { useEffect, useState } from 'react';
import { Image } from 'react-bootstrap';

import {
  StepperCompleted,
  StepperCurrentNotCompleted,
  StepperNotCompleted,
} from '../../../assets/img/imageExport/images';
import './CustomStepper.css';

/**
 * Renders a custom stepper component.
 *
 * @param {Object} props - The props object.
 * @param {Array} props.steps - An array of objects representing each step.
 * @param {number} props.activeStepper - The index of the active step.
 * @param {Object} props.imageStyle - The style object for the step icon image.
 * @param {Object} props.lineStyle - The style object for the step bar.
 * @param {Object} props.titleStyle - The style object for the step title.
 * @param {Object} props.containerStyle - The style object for the stepper container.
 * @return {JSX.Element} The custom stepper component.
 */
const CustomStepper = ({
  steps,
  activeStepper,
  imageStyle,
  lineStyle,
  titleStyle,
  containerStyle,
}) => {
  const [currentStep, setCurrentStep] = useState(0);

  useEffect(() => {
    setCurrentStep(activeStepper);
  }, [activeStepper]);

  const getIcon = (stepIndex) => {
    if (stepIndex < activeStepper) {
      return <Image src={StepperCompleted} className="step-icon" style={{ ...imageStyle }} />;
    }
    if (stepIndex === currentStep) {
      return (
        <Image src={StepperCurrentNotCompleted} className="step-icon" style={{ ...imageStyle }} />
      );
    }
    return <Image src={StepperNotCompleted} className="step-icon" style={{ ...imageStyle }} />;
  };

  return (
    <div className="stepper-container">
      <div className="stepper" style={{ ...containerStyle }}>
        {steps.map(({ title }, index) => (
          <React.Fragment key={index}>
            <div className={`step ${index <= currentStep ? 'active' : ''}`}>
              {getIcon(index)}
              <div className="step-title" style={{ ...titleStyle }}>
                {title}
              </div>
            </div>

            {index < steps.length - 1 && (
              <div
                key={index}
                className={`step-bar ${index < currentStep ? 'active' : ''}`}
                style={{ ...lineStyle }}
              />
            )}
          </React.Fragment>
        ))}
      </div>
    </div>
  );
};

export default CustomStepper;
