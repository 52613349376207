import React from 'react';
import PropTypes from 'prop-types';
import DropdownSelect from '../DropdownSelect';
import { SALE_ORDER_DOCUMENT_STATUS } from '../../constants/Constants';

const SaleOrderDocumentStatusDropDown = ({ onChange, value, ...props }) => {
  const options = [
    SALE_ORDER_DOCUMENT_STATUS.PENDING,
    SALE_ORDER_DOCUMENT_STATUS.VALID_ADMIN,
    SALE_ORDER_DOCUMENT_STATUS.VALID_ACCOUNTS,
    SALE_ORDER_DOCUMENT_STATUS.INVALID_ADMIN,
    SALE_ORDER_DOCUMENT_STATUS.INVALID_ACCOUNTS,
    SALE_ORDER_DOCUMENT_STATUS.UPLOADED,
    SALE_ORDER_DOCUMENT_STATUS.REUPLOADED,
    SALE_ORDER_DOCUMENT_STATUS.DATA_UPDATED,
  ];
  return (
    <DropdownSelect
      getOptionLabel={(option) => {
        return option.name;
      }}
      formatOptionLabel={(option) => {
        const virtualStyle = {
          borderBottom: '1px solid',
        };
        return <div style={option.isVirtual ? virtualStyle : {}}>{option.name}</div>;
      }}
      onChange={onChange}
      getOptionValue={(option) => option.id}
      options={options}
      value={value}
      {...props}
    />
  );
};

SaleOrderDocumentStatusDropDown.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func,
};

export default SaleOrderDocumentStatusDropDown;
