import { useEffect } from 'react';
import { useIdleTimer } from 'react-idle-timer';
import { useHistory } from 'react-router-dom';
import { useStakeholders } from '../../hooks';
import { useAccount, useUserRoles } from '../../hooks/hooks';
import useResponsive from '../../hooks/useResponsive';

const useIdleTimeout = ({ idleTime = 1 }) => {
  const idleTimeout = 1000 * idleTime;

  const [{ valueAllLoginLogoutLogs }, { doCreateLoginLogoutLogs }] = useUserRoles();
  const { currentAccount } = useStakeholders();
  const { isMobile } = useResponsive();

  const { logout } = useAccount();
  const history = useHistory();
  const handleIdle = async () => {
    if (idleTimer.isLastActiveTab()) {
      logout();
      await doCreateLoginLogoutLogs({
        logType: 'user_active_session_timeout',
        userId: currentAccount?.id,
        isAvailable: currentAccount?.isAvailable === true && 'false',
        locationDetails: {
          isMobile,
        },
      });
      history?.push('/login');
    }
  };

  const handleTabFocus = () => {
    idleTimer.reset();
  };

  useEffect(() => {
    window.addEventListener('focus', handleTabFocus);
    return () => {
      window.removeEventListener('focus', handleTabFocus);
    };
  }, []);

  const idleTimer = useIdleTimer({
    crossTab: true,
    timeout: idleTimeout,
    onIdle: handleIdle,
    debounce: 500,
  });

  return {
    idleTimer,
  };
};

export default useIdleTimeout;
