import React from 'react';
import PropTypes from 'prop-types';
import DropdownSelect from '../DropdownSelect';
import { TRIP_STATUS } from '../../constants/Constants';

const TripStatusDropDown = ({ onChange, value, ...props }) => {
  const options = [
    TRIP_STATUS.TO_BE_DISPATCHED,
    TRIP_STATUS.DISPATCHED,
    TRIP_STATUS.ABOUT_TO_REACH,
    TRIP_STATUS.REACHED,
  ];
  return (
    <DropdownSelect
      getOptionLabel={(option) => {
        return option.name;
      }}
      formatOptionLabel={(option) => {
        const virtualStyle = {
          borderBottom: '1px solid',
        };
        return <div style={option.isVirtual ? virtualStyle : {}}>{option.name}</div>;
      }}
      onChange={onChange}
      getOptionValue={(option) => option.id}
      options={options}
      value={value}
      maxMenuHeight={150}
      {...props}
    />
  );
};

TripStatusDropDown.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func,
};

export default TripStatusDropDown;
