import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  groups: null,
  modules: null,
  roles: null,
};

const RoleSlice = createSlice({
  name: 'role',
  initialState,
  reducers: {
    setGroupsList: (state, action) => {
      state.groups = action.payload;
    },
    setModulesList: (state, action) => {
      state.modules = action.payload;
    },
    setRoleList: (state, action) => {
      state.roles = action.payload;
    },
  },
});
export const roleActions = RoleSlice.actions;
export default RoleSlice.reducer;
