import React from 'react';
import PropTypes from 'prop-types';
import { flatMap } from 'lodash';
import DropdownSelect from '../DropdownSelect';
import { GST_FILING_STATUS } from '../../constants/Constants';

const GSTStatusDropdown = ({ onChange, value, ...props }) => {
  const options = [
    GST_FILING_STATUS.NO_DELAYS,
    GST_FILING_STATUS.ONE_DELAY,
    GST_FILING_STATUS.TWO_DELAYS,
    GST_FILING_STATUS.THREE_DELAYS,
    GST_FILING_STATUS.FOUR_DELAYS,
    GST_FILING_STATUS.FIVE_DELAYS,
    GST_FILING_STATUS.SIX_DELAYS,
    GST_FILING_STATUS.SEVEN_DELAYS,
    GST_FILING_STATUS.EIGHT_DELAYS,
    GST_FILING_STATUS.NINE_DELAYS,
    GST_FILING_STATUS.TEN_DELAYS,
    GST_FILING_STATUS.ELEVEN_DELAYS,
    GST_FILING_STATUS.TWELVE_DELAYS,
  ];
  return (
    <DropdownSelect
      getOptionLabel={(option) => {
        return option.name;
      }}
      formatOptionLabel={(option) => {
        const virtualStyle = {
          borderBottom: '1px solid',
        };
        return <div style={option.isVirtual ? virtualStyle : {}}>{option.name}</div>;
      }}
      onChange={onChange}
      getOptionValue={(option) => option.id}
      options={options}
      value={value}
      maxMenuHeight={150}
      {...props}
    />
  );
};

GSTStatusDropdown.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func,
};

export default GSTStatusDropdown;
