import PropTypes from 'prop-types';
import React, { useEffect, useMemo } from 'react';
import { useAsyncEffect } from '../../../hooks';
import { useUserRoles } from '../../../hooks/hooks';
import DropdownSelect from '../DropdownSelect';

const AllMaterialTypeDropdown = ({
  onChange,
  value,
  selectedOptions = [],
  isFilter = true,
  isAllMaterialsRequired = false,
  ...props
}) => {
  let filteredArray = [];
  const [{ valueAllMaterialList }, { doGetAllMaterialTypesList }] = useUserRoles();

  useAsyncEffect(async () => {
    await doGetAllMaterialTypesList();
  }, []);

  const materialListDataOptions = useMemo(() => {
    const allCategoryObject = {
      id: -1,
      name: 'All',
    };
    if (!valueAllMaterialList) {
      return []; // Return an empty array if valueAllMaterialList is undefined or null.
    }
    const updatedList = isAllMaterialsRequired
      ? [...valueAllMaterialList]
      : [allCategoryObject, ...valueAllMaterialList];

    return updatedList?.map((option) => ({
      id: option?.name,
      name: option?.name,
    }));
  }, [valueAllMaterialList, selectedOptions, value]);

  // To exclude the selected options from the list
  if (!isFilter) {
    filteredArray = materialListDataOptions?.filter((obj) => {
      return value && !value?.some((excludeObj) => excludeObj?.name === obj?.name);
    });
  }

  return (
    <DropdownSelect
      options={isFilter ? materialListDataOptions : filteredArray}
      getOptionLabel={(option) => option?.name}
      getOptionValue={(option) => option?.id}
      value={value}
      onChange={onChange}
      {...props}
    />
  );
};

AllMaterialTypeDropdown.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func,
};

export default AllMaterialTypeDropdown;
