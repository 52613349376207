import React, { useCallback, useEffect } from 'react';
import { useTable, useBlockLayout, useFilters } from 'react-table';
import { debounce } from 'lodash';
import { usePagination, useSortBy } from 'react-table/dist/react-table.development';
import { Content, Loader, PaginationComponent } from '../../shared/components';

import { EmptyIcon } from '../../assets/img/imageExport/images';
import './SegmentationTable.scss';
import { parseTableFilters } from '../../shared/utils/Helper';
import { PAGE_CONFIG } from '../../shared/constants/Constants';

const SegmentationTable = ({
  columns,
  data,
  loading,
  onFilterUpdate,
  queryParamsConfig,
  setFilters,
  totalCount,
  filters,
}) => {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    state: { pageIndex, filters: tableFilters, sortBy },
    previousPage,
    canPreviousPage,
    nextPage,
    canNextPage,
    pageOptions,
    gotoPage,
    setAllFilters,
  } = useTable(
    {
      columns,
      initialState: {
        pageSize: PAGE_CONFIG?.size,
      },
      manualFilters: true,
      manualSortBy: true,
      manualPagination: true,
      pageCount: Math.ceil(totalCount / PAGE_CONFIG?.size),
      data: data || [],
    },
    useBlockLayout,
    useFilters,
    useSortBy,
    usePagination
  );

  useEffect(() => {
    setAllFilters([]);
  }, []);

  const debouncedFilterUpdate = useCallback(
    debounce((f) => {
      if (onFilterUpdate) {
        onFilterUpdate(f);
      }
    }, 300),
    []
  );

  useEffect(() => {
    const _filters = parseTableFilters(tableFilters);
    const updatedFilters = {
      ..._filters,
      size: filters?.size,
      page: 0,
      sort: filters?.sort,
    };

    debouncedFilterUpdate(updatedFilters);
  }, [tableFilters]);

  useEffect(() => {
    debouncedFilterUpdate({ ...filters, size: filters?.size, page: pageIndex });
  }, [pageIndex]);

  return (
    <>
      <React.Suspense fallback={<Loader />}>
        <div {...getTableProps()} className="SegmentationTable table sticky">
          <div className="header" style={{ minWidth: '100%' }}>
            {headerGroups.map((headerGroup) => (
              <React.Fragment key={headerGroup.id}>
                <div
                  {...headerGroup.getHeaderGroupProps()}
                  className="tr"
                  style={{ display: 'flex', width: '100%' }}>
                  {headerGroup.headers.map((column) => (
                    <div
                      key={column.id}
                      {...column.getHeaderProps()}
                      className="th border-right d-flex flex-row align-items-center"
                      style={{ width: '100%' }}>
                      {column.render('Header')}
                    </div>
                  ))}
                </div>
                <div
                  {...headerGroup.getHeaderGroupProps()}
                  className="tr filter-row"
                  style={{ display: 'flex', width: '100%' }}>
                  {headerGroup.headers.map((column) => (
                    <div
                      key={column.id}
                      {...column.getHeaderProps()}
                      className="th filter"
                      style={{ width: '100%' }}>
                      {column.Filter ? column.render('Filter') : null}
                    </div>
                  ))}
                </div>
              </React.Fragment>
            ))}
          </div>

          <div {...getTableBodyProps()} className="body">
            <Content
              loading={loading}
              emptyIcon={EmptyIcon}
              emptyResultMessage="There are no Segmentation for selected criteria"
              hasData={data?.length > 0}
              emptyMessage="There are no Segmentation">
              {rows.map((row) => {
                prepareRow(row);
                return (
                  <div
                    key={row.id}
                    {...row.getRowProps()}
                    className="tr"
                    style={{ display: 'flex', width: '100%' }}>
                    {row.cells.map((cell) => (
                      <div
                        key={cell.id}
                        {...cell.getCellProps()}
                        className="td"
                        style={{ width: '100%' }}>
                        {cell.render('Cell')}
                      </div>
                    ))}
                  </div>
                );
              })}
            </Content>
          </div>
        </div>
        <PaginationComponent
          canPreviousPage={canPreviousPage}
          previousPage={previousPage}
          canNextPage={canNextPage}
          nextPage={nextPage}
          loading={loading && data?.length}
          gotoPage={gotoPage}
          currentPage={pageIndex}
          numberOfPages={pageOptions?.length || 0}
          totalCount={totalCount}
        />
      </React.Suspense>
    </>
  );
};

export default SegmentationTable;
