const Global = {
  mobMaxWidth: 767, // anything <= this should show only in mobile
  LOGISTIC_WEIGHTS: {
    w_8000: 8000,
    w_10000: 10000,
    w_15000: 15000,
    w_17500: 17500,
  },
  LOGISTIC_SLABS: [
    {
      id: '8000',
      name: '8000',
    },
    {
      id: '10000',
      name: '10000',
    },
    {
      id: '15000',
      name: '15000',
    },
    {
      id: '17500',
      name: '17500',
    },
  ],
};

export default Global;
