import axios from 'axios';
import ls from 'localstorage-slim';
import React, { Suspense, useEffect, useState } from 'react';
import { Button, ButtonToolbar, Col, FormControl, Row } from 'react-bootstrap';
import { useLocation, useHistory } from 'react-router-dom';
import InfiniteScroll from 'react-infinite-scroll-component';
import { isEmpty, isUndefined, sum, sumBy } from 'lodash';
import { DoShow, PoDate, PrimaryButton } from '../../shared/components/GenericComponent';
import { Content, Loader, PageContainer } from '../../shared/components';
import { BackIcon, EmptyIcon, RefreshIcon } from '../../assets/img/imageExport/images';
import { displayToastMessage, hasError } from '../../shared/utils/Helper';
import AssignSaleOrderForPO from './AssignSaleOrderForPO';
import './PurchaseOrderDetails.scss';
import { useAsyncEffect } from '../../hooks';
import { usePurchaseOrder, useSaleOrder } from '../../hooks/hooks';
import { TOAST_TYPES } from '../../shared/constants/Constants';
import { ErrorCode } from '../../shared/constants';
import useResponsive from '../../hooks/useResponsive';
import Theme from '../../theme/Theme';

const CreateShipmentForPO = (props) => {
  const { match } = { ...props };
  const location = useLocation();
  const poData = location?.state?.poData;
  const { id } = match.params;
  const [pickupScheduleDate, setPickupScheduleDate] = useState('');
  // const saleOrderData = location?.state?.poData;

  const [selectedSalesOrderId, setSelectedSalesOrderId] = useState('');
  const [hasMoreData, setHasMoreData] = useState(true);
  const [selectSaleOrder, setSelectSaleOrder] = useState(false);
  const [saleOrdersList, setSaleOrdersList] = useState([]);
  const [
    {
      errorSalesOrdersList,
      loadingSalesOrdersList,
      valueSalesOrdersList,
      createShipmentValue,
      createShipmentLoading,
    },
    { doGetSalesOrders, doCreateShipment },
  ] = usePurchaseOrder();
  const [
    {
      authorizeTrackingError,
      authorizeTrackingValue,
      authorizeTrackingLoading,
      driverConsentLoading,
    },
    { sendDriverConsent, sendTrackingAuthenticate },
  ] = useSaleOrder();
  const [searchInput, setSearchInput] = useState('');
  const { isMobile } = useResponsive();

  const colors = Theme.Colors;
  const primaryPOItemId = poData?.mpPurchaseOrderItems?.[0]?.itemId;
  const poItemstotalQuantity = sumBy(poData?.mpPurchaseOrderItems, 'quantity');

  const queryParamsConfig = {
    id: undefined,
    buyerName: undefined,
    page: undefined,
    searchKey: undefined,
    size: 10,
    sort: 'createdDate,desc',
    purchaseOrderId: id,
    shipmentCreationDetails: true,
    itemId: primaryPOItemId,
    quantity: poItemstotalQuantity,
    status: 'IN_PROGRESS, APPROVED',
  };
  const [filters, setFilters] = useState(queryParamsConfig);
  const [currentPage, setCurrentPage] = useState(0);
  const history = useHistory();

  const [
    { getValue, loader, getError, rejecting, POItemsError, POItemsLoading, POItemsValue },
    { doGetPurchaseOrderById, doRejectPo, doAddEditPoItems, doDeletePoItem },
  ] = usePurchaseOrder();

  const getAssignRequisition = (reqFilters) => {
    return doGetSalesOrders({
      ...filters,
      ...reqFilters,
    });
  };

  const loadMoreRequsitions = async () => {
    setFilters({ ...filters, page: currentPage });
    const saleOrders = await getAssignRequisition({ ...filters, page: currentPage });
    const err = hasError(saleOrders);
    if (!err && saleOrders && saleOrders?.data) {
      const { data, totalCount } = saleOrders;
      if ([...saleOrdersList, ...data]?.length < totalCount) setHasMoreData(true);
      else setHasMoreData(false);
      setSaleOrdersList((prev) => [...prev, ...saleOrders?.data]);
      setCurrentPage((prev) => prev + 1);
    }
  };
  useAsyncEffect(() => loadMoreRequsitions(0), []);

  const handleSelectRequistion = (requisitionId) => {
    setSelectSaleOrder(requisitionId);
  };

  const poDetails = async (poId) => {
    await doGetPurchaseOrderById(poId);
  };

  const assignSOToPO = async () => {
    const payload = {
      purchaseOrderId: poData?.id,
      salesOrderId: selectSaleOrder,
      pickUpScheduledAt: pickupScheduleDate,
    };

    const result = await doCreateShipment(payload);
    const err = hasError(result);
    if (err) {
      displayToastMessage(TOAST_TYPES?.ERROR, err?.message, false);
      return;
    }
    displayToastMessage(TOAST_TYPES?.SUCCESS, ErrorCode?.SHIPMENT_CREATION);
    if (result?.truckDriverDetailsDTO) {
      let subscriptionPayload = {};
      let authorizePayload = {};
      let result1 = {};
      let authenticateBearerToken = null;

      if (poData?.listing?.materialLoaded === true) {
        authorizePayload = {
          username: 'marketplace',
          password: 'admin',
          rememberMe: false,
        };
        subscriptionPayload = {
          mobile: result?.truckDriverDetailsDTO?.contactNumber,
          vehicleNo: result?.truckDriverDetailsDTO?.vehicleNumber,
          appCode: 'MKT006',
          orderId: result?.id,
          firstName: null,
          lastName: null,
          sourceAddress: JSON.stringify(result?.shipFromAddressDTO),
          destinationAddress: JSON.stringify(result?.shipToAddressDTO),
        };
        if (
          process.env.REACT_APP_ENV === 'PROD' ||
          process.env.REACT_APP_ENV === 'STAGE' ||
          process.env.REACT_APP_ENV === 'DEMO'
        ) {
          const authorizeResult = await sendTrackingAuthenticate(
            authorizePayload,
            process.env.REACT_APP_ENV === 'PROD' ? 'prod' : 'stage'
          );
          if (authorizeResult?.code === 200) {
            authenticateBearerToken = authorizeResult?.data?.accessToken;
          }
          if (authenticateBearerToken) {
            axios.defaults.headers.common.Authorization = `Bearer ${authenticateBearerToken}`;
          }
          result1 = await sendDriverConsent(
            subscriptionPayload,
            process.env.REACT_APP_ENV === 'PROD' ? 'prod' : 'stage'
          );

          if (!isUndefined(result1) && result1.code !== 200) {
            displayToastMessage(
              TOAST_TYPES?.ERROR,
              `Tracking subscription not done.
     Please try to reach Logistic Team. \n ${result1.message}.`,
              false
            );
          } else if (isUndefined(result1)) {
            return;
          } else {
            displayToastMessage(TOAST_TYPES?.SUCCESS, ErrorCode?.TRIP_SUBSCRIBED);
          }
        }
        const token = ls?.get('userToken', { decrypt: true });
        axios.defaults.headers.common.Authorization = `Bearer ${token}`;
      }
    }
    // poDetails(poData?.id);
    history.replace(`/orders/${poData?.id}/details-po`);
  };

  const searchSaleOrders = async (input) => {
    let saleOrdersFilters = { ...filters, page: 0 };
    if (isEmpty(input)) saleOrdersFilters = { ...saleOrdersFilters, searchKey: undefined };
    else saleOrdersFilters = { ...saleOrdersFilters, searchKey: input };
    const saleOrders = await getAssignRequisition(saleOrdersFilters);
    const err = hasError(saleOrders);
    if (!err && saleOrders && saleOrders?.data) {
      const { data, totalCount } = saleOrders;
      if (data?.length < totalCount) setHasMoreData(true);
      else setHasMoreData(false);
      setSaleOrdersList(saleOrders?.data);
      setCurrentPage(0);
    }
  };

  const handleSaleOrderSearch = () => {
    // setSearchInput(e?.target?.value);
    searchSaleOrders(searchInput);
  };

  return (
    <Suspense fallback={<Loader />}>
      <PageContainer
        className="PurchaseOrderDetails"
        style={{ backgroundColor: colors.bg_secondary }}>
        <Row>
          <Col xs={6} className="mt-2">
            <h4 className="text-large">
              <BackIcon onClick={() => window.history.back()} className="back-icon" />
              Create Shipment
            </h4>
          </Col>
          <Col xs={6}>
            <ButtonToolbar className="float-right">
              <PrimaryButton
                label="Save"
                size="md"
                onClick={() => assignSOToPO()}
                disabled={
                  pickupScheduleDate === '' ||
                  createShipmentLoading ||
                  authorizeTrackingLoading ||
                  driverConsentLoading
                }
                buttonLoading={
                  createShipmentLoading || authorizeTrackingLoading || driverConsentLoading
                }
              />
            </ButtonToolbar>
          </Col>
        </Row>
        <hr />
        <Row className={isMobile && 'pl-1'}>
          <Col xs="6" md="4" className="pr-0">
            <FormControl
              className={!isMobile ? 'search-field-saleorder' : 'search-field-saleorder-pwa'}
              placeholder="Search by SaleOrder Id, Buyer Name"
              onChange={(e) => setSearchInput(e?.target?.value)}
              value={searchInput}
            />
          </Col>
          <Col className="ml-2 pl-0">
            <ButtonToolbar>
              <PrimaryButton
                label="Search"
                size="md"
                disabled={searchInput === ''}
                onClick={() => handleSaleOrderSearch()}
              />
              <RefreshIcon
                className="ml-1"
                style={{ height: '2.5rem', marginTop: '0.3rem' }}
                onClick={() => {
                  setSearchInput('');
                  searchSaleOrders('');
                }}
              />
            </ButtonToolbar>
          </Col>
        </Row>
        <Content
          emptyIcon={EmptyIcon}
          emptyResultMessage
          loading={loadingSalesOrdersList && currentPage === 0}
          // complete page should not be reloaded every time
          hasData={saleOrdersList?.length > 0}
          emptyMessage="There are no active sales order for the purchase order">
          <div
            id="scrollableDiv"
            className={isMobile ? 'overflow-auto p-2' : 'overflow-auto scrollBarHide'}>
            <InfiniteScroll
              dataLength={saleOrdersList?.length}
              next={loadMoreRequsitions}
              hasMore={hasMoreData}
              loader={<Loader text="Fetching More Sales Orders" />}
              scrollableTarget="scrollableDiv">
              {saleOrdersList?.map((so) => (
                <AssignSaleOrderForPO
                  selectSaleOrder={selectSaleOrder}
                  setSelectSaleOrder={setSelectSaleOrder}
                  handleSelectRequistion={handleSelectRequistion}
                  saleOrderData={so}
                  saleOrderId={so?.id}
                  pickupScheduleDate={pickupScheduleDate}
                  setPickupScheduleDate={setPickupScheduleDate}
                />
              ))}
            </InfiniteScroll>
          </div>
        </Content>
      </PageContainer>
    </Suspense>
  );
};

export default CreateShipmentForPO;
