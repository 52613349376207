export const transformedVendorSegmentValues = (segmentValues) => {
  return segmentValues?.vendorSegmentValues?.map((segment) => {
    const valueTypes = [];
    if (
      segment.txnMinValue !== undefined &&
      segment.txnMinValue !== 0 &&
      segment.txnMaxValue !== undefined &&
      segment.txnMaxValue !== 0
    ) {
      valueTypes.push({
        valueType: 'Transactions',
        minValue: segment.txnMinValue,
        maxValue: segment.txnMaxValue,
      });
    }

    if (
      segment.qtyMinValue !== undefined &&
      segment.qtyMinValue !== 0 &&
      segment.qtyMaxValue !== undefined &&
      segment.qtyMaxValue !== 0
    ) {
      valueTypes.push({
        valueType: 'Quantity',
        minValue: segment.qtyMinValue,
        maxValue: segment.qtyMaxValue,
      });
    }

    if (
      segment.cashChallamMinValue !== undefined &&
      segment.cashChallamMinValue !== 0 &&
      segment.cashChallanMaxValue !== undefined &&
      segment.cashChallanMaxValue !== 0
    ) {
      valueTypes.push({
        valueType: 'Cash Challan Paid',
        minValue: segment.cashChallamMinValue,
        maxValue: segment.cashChallanMaxValue,
      });
    }

    if (
      segment.marginMinValue !== undefined &&
      segment.marginMinValue !== 0 &&
      segment.marginMaxValue !== undefined &&
      segment.marginMaxValue !== 0
    ) {
      valueTypes.push({
        valueType: 'Margin',
        minValue: segment.marginMinValue,
        maxValue: segment.marginMaxValue,
      });
    }
    if (
      segment.sellerRatingMinValue !== undefined &&
      segment.sellerRatingMinValue !== 0 &&
      segment.sellerRatingMaxValue !== undefined &&
      segment.sellerRatingMaxValue !== 0
    ) {
      valueTypes.push({
        valueType: 'Seller Rating',
        minValue: segment.sellerRatingMinValue,
        maxValue: segment.sellerRatingMaxValue,
      });
    }

    return {
      id: segment.id,
      segmentName: segment.segmentName,
      valueTypes,
    };
  });
};

export const extendTypesValues = (venderTypesData) => {
  return venderTypesData?.map((item, index) => {
    return { id: index + 1, value: item };
  });
};

export const extractedMaterialType = (materialTypes) => {
  return materialTypes?.map((item) => {
    return { id: item.id, value: item.name };
  });
};

export const extractAppliedConditionsOn = () => {
  const updatedConditions = [
    { id: 1, value: 'Transactions' },
    { id: 2, value: 'Quantity' },
    { id: 3, value: 'Cash Challan Paid' },
    { id: 4, value: 'Margin' },
    { id: 5, value: 'Seller Rating' },
  ];
  return updatedConditions;
};

export const buildConditionsObject = (condition) => {
  const filterConditions = condition.map((item) => item.value);
  const conditionObj = {};
  filterConditions?.map((val) => {
    if (val === 'Transactions') {
      return (conditionObj.condition1 = 'Transactions');
    }
    if (val === 'Quantity') {
      return (conditionObj.condition2 = 'Quantity');
    }
    if (val === 'Cash Challan Paid') {
      return (conditionObj.condition3 = 'Cash Challan Paid');
    }
    if (val === 'Margin') {
      return (conditionObj.condition4 = 'Margin');
    }
    if (val === 'Seller Rating') {
      return (conditionObj.condition5 = 'Seller Rating');
    }
    return '';
  });
  return JSON.stringify([conditionObj]);
};

export const getNotLimit = (maxValue) => {
  if (maxValue === 'No Limit') {
    return 999999;
  }
  return Number(maxValue);
};

export const segmentObject = (vendorSegmentData) => {
  const transformedVendorSegment = vendorSegmentData?.map((segment, index) => {
    const result = {
      segmentName: segment.segmentName,
      id: segment.id,
      active: segment?.active,
    };

    segment.valueTypes.forEach((valueType) => {
      if (valueType.valueType === 'Transactions') {
        result.txnMinValue = Number(valueType.minValue);
        result.txnMaxValue = getNotLimit(valueType.maxValue);
      } else if (valueType.valueType === 'Quantity') {
        result.qtyMinValue = Number(valueType.minValue);
        result.qtyMaxValue = getNotLimit(valueType.maxValue);
      } else if (valueType.valueType === 'Cash Challan Paid') {
        result.cashChallamMinValue = Number(valueType.minValue);
        result.cashChallanMaxValue = getNotLimit(valueType.maxValue);
      } else if (valueType.valueType === 'Margin') {
        result.marginMinValue = Number(valueType.minValue);
        result.marginMaxValue = getNotLimit(valueType.maxValue);
      } else if (valueType.valueType === 'Seller Rating') {
        result.sellerRatingMinValue = Number(valueType.minValue);
        result.sellerRatingMaxValue = getNotLimit(valueType.maxValue);
      }
    });

    return result;
  });
  return transformedVendorSegment;
};

export const extractConditionDetails = (conditions) => {
  if (conditions) {
    const conditionsParsed = JSON.parse(conditions);
    const filteredCondition = conditionsParsed.map((item) => Object.values(item)).flat();

    const extractConditions = filteredCondition.map((item, index) => {
      return {
        id: index + 1,
        value: item,
      };
    });
    return extractConditions;
  }
  return '';
};

export const editSegmentation = (array1, array2, apiData) => {
  if (!apiData) return [];
  if (!array1) return [];
  const value1 = array1?.map((item) => item.value);

  const value2 = JSON.parse(array2)
    ?.map((item) => Object.values(item))
    .flat();

  const uniqueArray1 = value1?.filter((item) => !value2?.includes(item));
  const uniqueArray2 = value2?.filter((item) => !value1?.includes(item));

  const uniqueElements = [...uniqueArray1, ...uniqueArray2];

  const newSegmentation = apiData?.map((item) => {
    const existingValueTypes = item?.valueTypes?.map((vt) => vt.valueType);

    const updatedValueTypes = uniqueElements?.reduce((acc, value) => {
      if (existingValueTypes?.includes(value)) {
        // Remove the existing value
        return acc?.filter((vt) => vt.valueType !== value);
      }
      return [...acc, { valueType: value, maxValue: '', minValue: '' }];
    }, item.valueTypes);

    return {
      ...item,
      valueTypes: updatedValueTypes,
      active: true,
    };
  });
  return newSegmentation;
};
