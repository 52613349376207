import {
  encodeDelimitedArray,
  decodeDelimitedArray,
  decodeDelimitedNumericArray,
  encodeDelimitedNumericArray,
} from 'use-query-params';

/** Uses a comma to delimit entries. e.g. ['a', 'b'] => qp?=a,b */
export const CommaArrayParam = {
  encode: (array = undefined) => encodeDelimitedArray(array, ','),

  decode: (arrayStr = undefined) => decodeDelimitedArray(arrayStr, ','),
};

/** Uses a comma to delimit entries. e.g. ['a', 'b'] => qp?=a,b */
export const CommaArrayNumberParam = {
  encode: (array = undefined) => encodeDelimitedNumericArray(array, ','),

  decode: (arrayStr = undefined) => decodeDelimitedNumericArray(arrayStr, ','),
};
