import React, { useCallback, useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';
import { Field, useFormikContext } from 'formik';

import './MinMaxCard.scss';

/**
 * Renders a MinMaxCard component that displays a title,
 * a minimum and maximum value input field, and a checkbox to toggle between "No Limit"
 * and a specific maximum value.
 *
 * @param {Object} props - The properties passed to the component.
 * @param {string} props.title - The title to be displayed.
 * @param {number} props.index - The index of the value type.
 * @param {number} props.parentIndex - The index of the vendor segment value.
 * @return {JSX.Element} The rendered MinMaxCard component.
 */
const MinMaxCard = ({ title, index, parentIndex }) => {
  const { errors, values, setFieldValue, touched } = useFormikContext();

  const [checked, setChecked] = useState(false);

  useEffect(() => {
    setChecked(values.vendorSegmentValues[parentIndex].valueTypes[index].maxValue === 999999);
  }, [values.vendorSegmentValues?.length]);

  /**
   * Toggles the checked state of the checkbox and updates the
   * corresponding field value in the form.
   *
   * @return {void} No return value.
   */
  const onCheckBox = () => {
    setChecked(!checked);
    setFieldValue(
      `vendorSegmentValues[${parentIndex}].valueTypes[${index}].maxValue`,
      !checked ? 999999 : 0
    );
  };

  /**
   * Updates the maximum value in the vendor segment values array based on the provided event.
   *
   * @param {Event} e - The event object containing the target value.
   * @return {void} This function does not return anything.
   */
  const onMaxValueChange = (e) => {
    if (e.target.value !== 'No Limit') {
      setChecked(false);
    }
    setFieldValue(
      `vendorSegmentValues[${parentIndex}].valueTypes[${index}].maxValue`,
      e.target.value
    );
  };

  /**
   * Updates the minimum value in the vendor segment values array based on the provided event.
   *
   * @param {Event} e - The event object containing the target value.
   * @return {void} This function does not return anything.
   */
  const onMinValueChange = (e) => {
    setFieldValue(`vendorSegmentValues[${parentIndex}].valueTypes[${index}].valueType`, title);
    setFieldValue(
      `vendorSegmentValues[${parentIndex}].valueTypes[${index}].minValue`,
      e.target.value
    );
  };

  const checkNotLimit = (pi, i) => {
    if (values.vendorSegmentValues[pi].valueTypes[i].maxValue === 999999) {
      return 'No Limit';
    }
    return values.vendorSegmentValues[pi].valueTypes[i].maxValue;
  };

  const setName = (name) => {
    switch (name) {
      case 'Cash Challan Paid':
        return 'Cash Challan Paid (%)';
      case 'Margin':
        return 'Margin (%)';
      default:
        return name;
    }
  };

  return (
    <div className="MinMaxCard container">
      <h5 style={{ fontSize: '16px' }}>{setName(title)}</h5>
      <div className="subContainer" style={{ justifyContent: 'space-between' }}>
        <div style={{ width: '40%' }}>
          <Form.Label className="labelSty">Minimum</Form.Label>
          <Field
            name={`vendorSegmentValues[${parentIndex}]?.valueTypes[${index}].minValue]`}
            value={values?.vendorSegmentValues[parentIndex]?.valueTypes[index].minValue}
            className="form-control"
            style={{ borderRadius: '6px' }}
            placeholder="Please Enter Minimum Value"
            onChange={(e) => onMinValueChange(e)}
          />
          <Form.Control.Feedback type="isInvalid">
            <span style={{ color: '#ff7272' }}>
              {touched?.vendorSegmentValues?.[parentIndex]?.valueTypes?.[index]?.minValue &&
                errors?.vendorSegmentValues?.[parentIndex]?.valueTypes?.[index]?.minValue}
            </span>
          </Form.Control.Feedback>
        </div>
        <p className="hyphenSty" style={{ alignSelf: 'flex-end' }}>
          -
        </p>
        <div style={{ width: '40%' }}>
          <div className="spaceContainer">
            <Form.Label className="labelSty">Maximum</Form.Label>
            <div className="d-flex" style={{ justifyContent: 'space-between' }}>
              <input
                type="checkbox"
                style={{ marginTop: '0px' }}
                onChange={onCheckBox}
                checked={checked}
              />
              <h5 className="limitSty">No Limit</h5>
            </div>
          </div>
          <Field
            name={`vendorSegmentValues[${parentIndex}]?.valueTypes[${index}].maxValue`}
            className="form-control"
            style={{ borderRadius: '6px' }}
            placeholder="Please Enter Maximum Value"
            value={checkNotLimit(parentIndex, index)}
            onChange={(e) => onMaxValueChange(e)}
          />
          <Form.Control.Feedback type="isInvalid">
            <span style={{ color: '#ff7272' }}>
              {touched?.vendorSegmentValues?.[parentIndex]?.valueTypes?.[index]?.maxValue &&
                errors?.vendorSegmentValues?.[parentIndex]?.valueTypes?.[index]?.maxValue}
            </span>
          </Form.Control.Feedback>
        </div>
      </div>
    </div>
  );
};

export default MinMaxCard;
