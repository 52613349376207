/* eslint-disable no-nested-ternary */
import { useFormik } from 'formik';
import { isEmpty } from 'lodash';
import React, { useState } from 'react';
import { Button, Col, Form, FormControl, Image, InputGroup, Modal } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import {
  AlertCircleSVG,
  CheckCircleSVG,
  HideEyeIconDownload,
  ResetPasswordUserAvatar,
  ShowEyeIconDownload,
} from '../../assets/img/imageExport/images';
import { useAccount, useAccountPassword } from '../../hooks/hooks';
import { ErrorCode } from '../../shared/constants';
import {
  LOWERCASE_REGEX,
  NUMBER_REGEX,
  SPECIALCHARACTERS_REGEX,
  TOAST_TYPES,
  UPPERCASE_REGEX,
} from '../../shared/constants/Constants';
import { displayToastMessage, hasError } from '../../shared/utils/Helper';
import PasswordStrengthMeter from '../../views/ResetPasswordPage/PasswordStrengthMeter';
import './UserChangePassword.scss';

const UserChangePassword = ({ show, setShow }) => {
  const [showOldPassword, setShowOldPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [passwordCriteriaCount, setPasswordCriteriaCount] = useState(0);
  const [, doUpdateAccount, doGetAccount, doUpdatePassword] = useAccountPassword();
  const { logout } = useAccount();
  const history = useHistory();

  const formik = useFormik({
    initialValues: {
      oldPassword: '',
      newPassword: '',
    },
    validationSchema: () => {
      return Yup.lazy(() => {
        return Yup.object().shape({
          oldPassword: Yup.string().required('Old Password is required.'),
          newPassword: Yup.string().required('New Password is required.'),
        });
      });
    },
    enableReinitialize: true,
    validateOnBlur: true,
    validateOnChange: true,
    onSubmit: async (values) => {
      const payload = {
        currentPassword: values?.oldPassword,
        newPassword: values?.newPassword,
      };
      const response = await doUpdatePassword(payload);
      const error = hasError(response);
      if (error) {
        displayToastMessage(TOAST_TYPES?.ERROR, error?.message, false);
      } else {
        displayToastMessage(TOAST_TYPES?.SUCCESS, ErrorCode?.UPDATE_PASSWORD);
        setTimeout(async () => {
          logout();
          history?.push('/login');
          window?.location?.reload(true);
        }, 1800);
      }
      closeModal();
    },
  });

  const closeModal = () => {
    setShow(false);
  };

  return (
    <Modal
      show={show}
      onHide={closeModal}
      className="UserChangePassword"
      onExit={() => formik?.resetForm()}
      size="md"
      backdrop="static"
      centered>
      <Modal.Header className="text-large" closeButton onHide={closeModal}>
        <Modal.Title>
          <div className="d-flex flex-row align-items-center">
            <Image src={ResetPasswordUserAvatar} height={40} />
            <h5 className="font-regular ml-3">Change Password</h5>
          </div>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="p-4">
        <Form noValidate>
          <Form.Row>
            <Form.Group as={Col} md="12">
              <Form.Label className="font-text">
                Old Password<span className="mandatory-astrik"> *</span>
              </Form.Label>
              <InputGroup>
                <FormControl
                  type={showOldPassword ? 'text' : 'password'}
                  placeholder="Enter Old password"
                  {...formik?.getFieldProps('oldPassword')}
                  isInvalid={formik?.touched?.oldPassword && !!formik?.errors?.oldPassword}
                  className="font-text pt-4 pb-4 confirm-input"
                />

                <InputGroup.Append>
                  <InputGroup.Text className="confirm-input-icon">
                    <Image
                      src={showOldPassword ? ShowEyeIconDownload : HideEyeIconDownload}
                      height={18}
                      onClick={() => setShowOldPassword(!showOldPassword)}
                    />
                  </InputGroup.Text>
                </InputGroup.Append>
                <Form.Control.Feedback type="invalid" className="text-validation">
                  {formik?.touched?.oldPassword && formik?.errors?.oldPassword}
                </Form.Control.Feedback>
              </InputGroup>
            </Form.Group>
            <Form.Group as={Col} md="12">
              <Form.Label className="font-text">
                Set New Password<span className="mandatory-astrik"> *</span>
              </Form.Label>
              <InputGroup>
                <FormControl
                  type={showNewPassword ? 'text' : 'password'}
                  className="font-text pt-4 pb-4 confirm-input"
                  placeholder="Enter new password"
                  {...formik?.getFieldProps('newPassword')}
                  isInvalid={formik?.touched?.newPassword && !!formik?.errors?.newPassword}
                />

                <InputGroup.Append>
                  <InputGroup.Text className="confirm-input-icon">
                    <Image
                      src={showNewPassword ? ShowEyeIconDownload : HideEyeIconDownload}
                      height={18}
                      onClick={() => setShowNewPassword(!showNewPassword)}
                    />
                  </InputGroup.Text>
                </InputGroup.Append>

                <Form.Control.Feedback type="invalid" className="text-validation">
                  {formik?.touched?.newPassword && formik.errors?.newPassword}
                </Form.Control.Feedback>
              </InputGroup>
            </Form.Group>
          </Form.Row>
          <>
            <PasswordStrengthMeter
              passwordValue={formik?.values?.newPassword}
              passwordCriteriaCount={passwordCriteriaCount}
              setPasswordCriteriaCount={setPasswordCriteriaCount}
            />

            <div className="d-flex flex-row">
              <Image
                src={
                  isEmpty(formik?.values?.newPassword)
                    ? CheckCircleSVG
                    : formik?.values?.newPassword.length >= 8
                    ? CheckCircleSVG
                    : AlertCircleSVG
                }
                height={13}
                className="mt-1 mr-1 ml-2"
              />
              <span
                style={{
                  color: isEmpty(formik?.values?.newPassword)
                    ? '#707070'
                    : formik?.values?.newPassword.length >= 8
                    ? '#707070'
                    : '#FF7272',
                }}>
                Minimum 8 characters
              </span>
            </div>

            <div className="d-flex flex-row">
              <Image
                src={
                  isEmpty(formik?.values?.newPassword)
                    ? CheckCircleSVG
                    : new RegExp(LOWERCASE_REGEX).test(formik?.values?.newPassword)
                    ? CheckCircleSVG
                    : AlertCircleSVG
                }
                height={13}
                className="mt-1 mr-1 ml-2"
              />
              <span
                style={{
                  color: isEmpty(formik?.values?.newPassword)
                    ? '#707070'
                    : new RegExp(LOWERCASE_REGEX).test(formik?.values?.newPassword)
                    ? '#707070'
                    : '#FF7272',
                }}>
                Minimum 1 Lowercase
              </span>
            </div>

            <div className="d-flex flex-row">
              <Image
                src={
                  isEmpty(formik?.values?.newPassword)
                    ? CheckCircleSVG
                    : new RegExp(UPPERCASE_REGEX).test(formik?.values?.newPassword)
                    ? CheckCircleSVG
                    : AlertCircleSVG
                }
                height={15}
                className="mt-1 mr-1 ml-2"
              />
              <span
                style={{
                  color: isEmpty(formik?.values?.newPassword)
                    ? '#707070'
                    : new RegExp(UPPERCASE_REGEX).test(formik?.values?.newPassword)
                    ? '#707070'
                    : '#FF7272',
                }}>
                Minimum 1 Uppercase
              </span>
            </div>
            <div className="d-flex flex-row mt-0 mb-0 pt-0 pb-0">
              <Image
                src={
                  isEmpty(formik?.values?.newPassword)
                    ? CheckCircleSVG
                    : new RegExp(SPECIALCHARACTERS_REGEX).test(formik?.values?.newPassword)
                    ? CheckCircleSVG
                    : AlertCircleSVG
                }
                height={13}
                className="mt-1 mr-1 ml-2"
              />
              <span
                style={{
                  color: isEmpty(formik?.values?.newPassword)
                    ? '#707070'
                    : new RegExp(SPECIALCHARACTERS_REGEX).test(formik?.values?.newPassword)
                    ? '#707070'
                    : '#FF7272',
                }}>
                Minimum 1 special character
              </span>
            </div>
            <div className="d-flex flex-row mt-0 mb-0 pt-0 pb-0">
              <Image
                src={
                  isEmpty(formik?.values?.newPassword)
                    ? CheckCircleSVG
                    : new RegExp(NUMBER_REGEX).test(formik?.values?.newPassword)
                    ? CheckCircleSVG
                    : AlertCircleSVG
                }
                height={13}
                className="mt-1 mr-1 ml-2"
              />
              <span
                style={{
                  color: isEmpty(formik?.values?.newPassword)
                    ? '#707070'
                    : new RegExp(NUMBER_REGEX).test(formik?.values?.newPassword)
                    ? '#707070'
                    : '#FF7272',
                }}>
                Minimum 1 number
              </span>
            </div>
          </>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button
          type="submit"
          disabled={!formik?.isValid || passwordCriteriaCount < 5}
          onClick={formik.handleSubmit}
          style={{ width: '100%' }}>
          Save Password
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
export default UserChangePassword;
