import React from 'react';
import { Modal, Button } from 'react-bootstrap';
import { ButtonLoading } from '..';
import './Sidebar.scss';

/**
 * @deprecated
 * @param props
 * @return {JSX.Element}
 * @constructor
 */
const Sidebar = (props) => {
  const {
    show,
    setShow,
    title,
    children,
    hideDismissBtn,
    hideSuccessBtn,
    dismissBtnText,
    successBtnText,
    onDismiss,
    onSuccess,
    isDisable,
    loading = false,
  } = props;
  return (
    <Modal
      className="MySidebar right"
      animation
      show={show}
      onHide={() => {
        setShow(false);
      }}
      onExit={() => {
        children?.props?.formik?.resetForm();
      }}
      scrollable>
      <Modal.Header closeButton className="">
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>{children}</Modal.Body>
      <Modal.Footer>
        {!hideDismissBtn && (
          <Button disabled={loading} variant="secondary" onClick={onDismiss}>
            {dismissBtnText || 'Cancel'}
          </Button>
        )}
        {!hideSuccessBtn && (
          <ButtonLoading
            disabled={isDisable || loading}
            variant="primary"
            className="button-default"
            loading={loading}
            onClick={onSuccess}>
            {successBtnText || 'Yes'}
          </ButtonLoading>
        )}
      </Modal.Footer>
    </Modal>
  );
};

export default Sidebar;
